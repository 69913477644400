// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "<div class=\"backendCheckboxRegion\"></div>\n<div class=\"backendConfigRegion\"></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"wrapper trunksRow hidden\">\n    <p class=\"clusterAttr trunkTitle\">Trunk:</p>\n    <div class=\"trunksRegion\"></div>\n</div>\n<div class=\"wrapper\">\n    <p class=\"clusterAttr versionTitle\">Version:</p>\n    <div class=\"versionsRegion\"></div>\n</div>\n<div class=\"betaNoteRegion\"></div>\n<div class=\"wrapper\">\n    <p class=\"clusterAttr regionTitle\">Region:</p>\n    <div class=\"regionVal\">"
    + container.escapeExpression(((helper = (helper = helpers.region || (depth0 != null ? depth0.region : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"region","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"changeRegion\">change region</div>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.wekaUser : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div class=\"clientsCheckboxRegion\"></div>\n<div class=\"clientsConfigRegion\"></div>\n<div class=\"buttonRegion\"></div>\n<p class=\"smallText\">this will take you to AWS CloudFormation</p>\n<div class=\"separator\"></div>\n<div class=\"deploymentNoteRegion\"></div>\n<div class=\"clientsNoteRegion\"></div>\n";
},"useData":true});
