// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <span class=\"switch_label withSubLabel\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.leftLabel : depth0)) != null ? stack1.label : stack1), depth0))
    + "<br> <span class=\"small_text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.leftLabel : depth0)) != null ? stack1.subLabel : stack1), depth0))
    + "</span></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <span class=\"switch_label\">"
    + container.escapeExpression(((helper = (helper = helpers.leftLabel || (depth0 != null ? depth0.leftLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"leftLabel","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <span class=\"switch_label withSubLabel\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.rightLabel : depth0)) != null ? stack1.label : stack1), depth0))
    + "<br> <span class=\"small_text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.rightLabel : depth0)) != null ? stack1.subLabel : stack1), depth0))
    + "</span></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "    <span class=\"switch_label\">"
    + container.escapeExpression(((helper = (helper = helpers.rightLabel || (depth0 != null ? depth0.rightLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"rightLabel","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.leftLabel : depth0)) != null ? stack1.subLabel : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "<div class=\"switch_wrapper\">\n    <input id=\"switch_"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"switch switch--flat\" type=\"checkbox\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.checked : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n    <label for=\"switch_"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\"></label>\n</div>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.rightLabel : depth0)) != null ? stack1.subLabel : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});
