// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.withSelect : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"cancel\">Cancel</button>    \n                <button class=\"save\">Save</button>\n                <div class=\"export-info\">You can select up to 3 options at a time</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"export\">Export</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.showOnly3Options : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"show-button\">Show All</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <button class=\"show-button\">Show Less</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {};

  return "<div class=\"columns_wrapper formatRow\">\n    <div class=\"column_left\">\n        <h2 class=\"column_subtitle\">Start with</h2>\n        <h2 class=\"column_title\">Your Requirements</h2>\n        <div class=\"requirementsWrapper\">\n            <div class=\"capacityGroup\"></div>\n            <div class=\"performanceGroup\"></div>\n            <div class=\"customerAndDealGroup\"></div>\n            <div class=\"filtersGroup\"></div>\n            <div class=\"vendorsGroup\"></div>\n            <div class=\"regionGroup\"></div>\n        </div>\n        <div class=\"noteRegion\"></div>\n    </div>\n    <div class=\"column_right\">\n        <h2 class=\"column_subtitle\">View all</h2>\n        <div class=\"title_wrapper\">\n              <h2 class=\"column_title\">"
    + ((stack1 = ((helper = (helper = helpers.resultsTitle || (depth0 != null ? depth0.resultsTitle : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"resultsTitle","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</h2>\n              <div class=\"loaderRegion\"></div>\n        </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showExport : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"optionsHeaderWrapper\"></div>\n        <div class=\"optionsWrapper\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.limitOptions : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"debugConsole\"></div>\n    </div>\n    <div class=\"bom-modal\"></div>\n</div>\n";
},"useData":true});
