var strings = {
    CAPACITY_GROUP_HELP_TEXT: _.template(
        "This section lets you specify the capacity requirements for your applications."
    ),
    TOTAL_CAPACITY_HELP_TEXT: _.template(
        "<b>Capacity:</b> This field specifies the total file system usable capacity which is shared by all instances. You can specify the capacity in Terabytes or Gigabytes."
    ),
    TIERING_HELP_TEXT: _.template(
        "<b>Tiering:</b> Your AWS cluster can be based on SSD exclusively, or you can store files on a combination of local SSDs and S3 buckets. All files on SSD and S3 are available to the applications.<br>Data is tiered to the S3 bucket based on a tiering policy. \
                    <br>All recently written and accessed files will reside on SSDs.<br>The calculator will provide the cost of a fully populated S3 tier, however costs will depend on actual usage over time."
    ),
    SSD_CAPACITY_HELP_TEXT: _.template(
        "<b>SSD Capacity:</b> For tiered clusters, you can specify what percentage of the total capacity you would like to keep on local SSDs. The physical capacity will be calculated for you based on the percentage you specified."
    ),

    PERFORMANCE_GROUP_HELP_TEXT: _.template(
        "This section is optional and lets you specify your application performance requirements. Performance can be specified in either \
                    IOPS (I/O operations per seconds) or bandwidth (GBytes per second). Performance calculations rely on benchmarks of Weka systems. You may see the \
                    variants we used to get these benchmarks in our support page"
    ),
    RW_HELP_TEXT: _.template(
        "<b>R/W:</b> Specify the percentage of IO operations that are read operations vs. the total number of operations."
    ),
    IOPS_HELP_TEXT: _.template(
        "<b>IOPS:</b> Specify the number of IO operations in 1000s per second required for the whole cluster. This option will be hidden if bandwidth performance is chosen."
    ),
    BW_HELP_TEXT: _.template(
        "<b>Bandwidth:</b> Specify the Gigabytes/second required for the whole cluster.  This option will be hidden if IOPS performance is chosen."
    ),

    REGION_HELP_TEXT: _.template(
        "<b>Region:</b> You can specify the region to run the cluster, different regions have different Amazon instance and S3 pricing."
    ),
    SINGLE_REGION_NOTE_MSG: _.template(
        "Multi AZ Weka deployments require at least 3 AZs, and the chosen region does not support it."
    ),

    CONTACT_US_TITLE: _.template("Contact Us."),
    CONTACT_US_SUB_TITLE: _.template(
        "Your inputs will be automatically included."
    ),

    CLUSTER_CONFIG_TITLE: _.template("Deploy your WekaIO cluster"),
    CALC_BACKEND_ERROR_TITLE: _.template(
        "Failed calculating your requirements"
    ),
    CALC_BACKEND_ERROR_MSG: _.template(
        "<p><u>Error Type</u>: <%= type %> </p><br> \
                                    <p><u>Error Message</u>: <%= msg %> </p>"
    ),
    CALC_STATUS_ERROR_TITLE: _.template(
        "An error occurred while calculating your request:"
    ),
    CALC_STATUS_ERROR_MSG: _.template("Status: <%= status %> (<%= msg %>)"),
    CALC_CONNECTION_ERROR_TITLE: _.template("No Internet Connectivity"),
    CALC_CONNECTION_ERROR_MSG: _.template(
        "Error: Network Error (no connection)"
    ),

    BETA_VERSION_NOTE: _.template(
        "This is a pre-release version for demo purposes, do not use in production"
    ),
    OLD_VERSION_NOTE: _.template(
        "Selecting a non-default version is highly discouraged. Older versions are available for backward compatability only."
    ),
    DEPLOYMENT_CONNECTIVITY_NOTE: _.template(
        "Internet connectivity is required for deployment, please make sure to deploy from a public subnet"
    ),
    DEPLOYMENT_CLIENTS_NOTE: _.template(
        "Client instances incur additional AWS charges"
    ),
    PRE_DEPLOY_TEXT: _.template("this will take you to AWS CloudFormation"),
    POST_DEPLOY_TEXT: _.template(
        "You’ll be redirected to AWS CloudFormation in a few seconds…"
    ),
    GET_CFN_URL_FAILURE_TITLE: _.template(
        "Failure in Generating CloudFormation Template"
    ),
    GET_CFN_URL_FAILURE_MSG: _.template(
        "An error occurred while trying to generate CloudFormation template and continue for AWS deployment:\
                                    <br><i><%= error %></i>"
    ),

    S3_NOTE_TITLE: _.template(
        "Deployment of S3 needs to be done after system initialization"
    ),
    S3_NOTE_MSG: _.template(
        "Currently we do not support S3 automatic deployment. <br> \
        You may continue deployment without S3, and configure it after your system initialization. <br> <br> \
        S3 charges will not apply until S3 is in use."
    ),
    UNSUPPORTED_CONFIG_TITLE: _.template(
        "This configuration is not supported yet"
    ),
    LOGIN_NOTE_TITLE: _.template("Login to WekaIO"),
    LOGIN_NOTE_MSG: _.template(
        "You must be registered to WekaIO in order to deploy, please log in."
    ),
    LOGGED_IN_MSG: _.template("Hello <%= name %>!"),
    LOGGED_OUT_MSG: _.template("Hello, you are not logged in"),
    LOGGING_IN: _.template("Logging in..."),
    VERSION_FETCH_FAILURE_TITLE: _.template(
        "Failure in Fetching Available Versions"
    ),
    VERSION_FETCH_FAILURE_MSG: _.template(
        'Please check connectivity and try again. <br> \
                                            If the failure continues, contact \
                                            <a target="_blank" class="link" href="http://support.cloud.weka.io">support.cloud.weka.io</a>'
    ),
    PERFORMANCE_BENCHMARKS_NOTE: _.template(
        'Performance calculations rely on benchmarks of Weka systems. <br> \
    You may see the variants we used to get these benchmarks in our support page. <br>\
    If you don\'t reach expected performance you can contact our support which is 24/7 available on <a target="_blank" class="link" href="http://support.cloud.weka.io">support.cloud.weka.io</a>'
    ),
    PERFORMANCE_BENCHMARKS_NOTE_NO_PERF_REQ: _.template(
        'Performance calculations rely on benchmarks of Weka systems and assume usage 80% read operations & 20% write operations. <br> \
    You may see the variants we used to get these benchmarks in our support page. <br>\
    If you don\'t reach expected performance you can contact our support which is 24/7 available on <a target="_blank" class="link" href="http://support.cloud.weka.io">support.cloud.weka.io</a>'
    ),

    RESULTS_TITLE: _.template("Your Options"),
    DEFAULT_AMI_TITLE: _.template("Select an operating system"),
    CUSTOM_AMI_TITLE: _.template("Specify a custom AMI"),
    CUSTOM_AMI_LABEL: _.template("Custom AMI"),
    DEFAULT_VERSION_SUFFIX: _.template(" (default)"),

    EMAIL_WEKA_SUBJECT: _.template("I'd like to try out Weka"),

    GB: _.template("GB"),
    TB: _.template("TB"),

    LOGIN: _.template("login"),
    LOGOUT: _.template("logout"),

    OK: _.template("OK"),
    GOT_IT: _.template("Got it!"),
    CONTINUE_DEPLOYMENT: _.template("Continue Deployment"),
    TRY_AGAIN: _.template("Try Again"),
    LOGIN_BUTTON_LABEL: _.template("Click here to login"),
    DEPLOY_TO_AWS: _.template("Deploy to AWS"),

    CLUSTERS_TABLE_INSTANCE_HEADER: _.template("Instance<br>Type"),
    CLUSTERS_TABLE_SIZE_HEADER: _.template("Cluster<br>Size"),
    CLUSTERS_TABLE_CAPACITY_HEADER: _.template("Capacity<br>(<%= units %>)"),
    CLUSTERS_TABLE_SSD_CAPACITY_HEADER: _.template(
        "Usable Capacity<br>(<%= units %>)"
    ),
    CLUSTERS_TABLE_INSTANCE_CLICK: _.template("Stripe: <%= stripe_data %>+<%= stripe_protection %><br>Weka cores: <%= weka_cores %><br>"),
    CLUSTERS_TABLE_IOPS_HEADER: _.template("IOPS<br>(K)"),
    CLUSTERS_TABLE_BW_HEADER: _.template("BW<br>(Gbytes/sec)"),
    CLUSTERS_TABLE_COST_HEADER: _.template("Weka Cost<br>($/Hr)"),
    IOPS_INFO_TITLE: _.template("IOPS (K)"),
    BW_INFO_TITLE: _.template("BW (Gbytes/sec)"),
    COST_INFO_TITLE: _.template("Weka Cost ($/Hr)"),
    WEKA_COST_LABEL: _.template("SSD Rate"),
    WEKA_S3_COST_LABEL: _.template("OBS Rate"),
    MORE_INFO_LABEL: _.template("More info"),

    DOCS_LINK: _.template(
        "  <a class='docLink' target='_blank' href='<%= link %>'>(read more)</a>"
    ),
    REQ_GROUP_CAPACITY_LABEL: _.template("capacity"),
    REQ_REGION_LABEL: _.template("region"),
    REQ_PERFORMANCE_LABEL: _.template("performance"),
    REQ_RW_LABEL: _.template("R/W"),
    REQ_TOTAL_CAPACITY_LABEL: _.template("total capacity"),
    REQ_TIERING_LABEL: _.template("tiering"),
    REQ_SSD_PERCENTAGE_LABEL: _.template("SSD capacity (%)"),
    REQ_SSD_CAPACITY_LABEL: _.template("SSD capacity required"),
    BW: _.template("BW"),
    IOPS: _.template("IOPs"),
    BW_INPUT_PLACEHOLDER: _.template("100-999"),
    BW_INPUT_UNITS: _.template("GB/sec"),
    NO_TIERING_LABEL: _.template("SSD Only"),
    OBS_TIERING_LABEL: _.template("Object Store"),
    S3_TIERING_LABEL: _.template("SSD+S3"),

    LOGIN_FAILURE_TITLE: _.template("Login Failure"),
    CONTACT_US_FAILURE_TITLE: _.template("Failure in Sending Form"),
};

module.exports = strings;
