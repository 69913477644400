consts = require('../../../framework/consts.ecmascript')
utils = require('../../../framework/utils.coffee')
ExpiredTokenTmpl = require('./ExpiredToken.hbs')

class ExpiredToken extends Marionette.ItemView
    template: ExpiredTokenTmpl
    ui:
        actionLink: '.action'
    events:
        'click @ui.actionLink': 'sendPartnerEmail'

    initialize: (options)=>
        @email = options.email
        @partnername = options.partnername

    sendPartnerEmail: ()=>
        utils.post(consts.APIs.token, {
                email: @email,
                hostname: window.location.hostname,
                partnername: @partnername,
                email_url: consts.APIs.sendEmail
            })
            .done (response)=>
                alert("An email with access link was sent to your email address, please follow this link")

    serializeData: =>
        return {
            docsURL: consts.docsLinks.root
        }

module.exports = ExpiredToken
