// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<p class=\"userName\">"
    + alias4(((helper = (helper = helpers.nameMsg || (depth0 != null ? depth0.nameMsg : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"nameMsg","hash":{},"data":data}) : helper)))
    + "</p>\n<div class=\"log_wrapper\">\n    <p class=\"log\">("
    + alias4(((helper = (helper = helpers.logText || (depth0 != null ? depth0.logText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"logText","hash":{},"data":data}) : helper)))
    + ")</p>\n    <div class=\"loader_region\"></div>\n</div>";
},"useData":true});
