keyboard = require('../../framework/keyboard.coffee')
numberTmpl = require('./tmpl/numberInput.hbs')

class ValueModel extends Backbone.Model
    setVal: (val)=>
        @set('val', val)

    getVal: =>
        return @get('val')


class NumberInput extends Marionette.ItemView
    template: numberTmpl
    className: 'input_wrapper formatRow'
    ui:
        unitLabel: '.unit_label'
        input: '.number_input'
    events:
        'custom_change @ui.input': 'triggerChange'
        'input @ui.input': 'setValFromInput'
        'keypress @ui.input': 'keypress'
        'keydown @ui.input': 'keydown'
        'focusout @ui.input': 'resetToValidValue'
        'paste @ui.input': 'checkPastedText'

    triggerChange: ()=>
        @trigger('change', @getVal())

    unitChange: (sign)=>
        prev_val = @getVal()
        if sign == '*'
            val = prev_val*1000
        else
            val = prev_val/1000
        @setVal(val)
        new_val = @getVal()
        if prev_val == new_val
            @triggerChange()

    getValueException: (num)=>
        if @min? and num < @min
            return 'min'
        if @max? and num > @max
            return 'max'
        if isNaN(num)
            return 'NaN'
        if not Number.isInteger(num) and not @float
            return 'int'
        return false

    getInputVal: ()=>
        return @ui.input.val()

    setInputVal: (val)=>
        @ui.input.val(val)

    setValFromInput: ()=>
        if @getInputVal() == ''
            @mark_bad_input(false)
            return
        val = Number(@getInputVal())
        if @getValueException(val)
            @mark_bad_input(true)
        else
            @mark_bad_input(false)
            @valModel.setVal(val)

    getVal: =>
        return @valModel.getVal()

    resetToValidValue: =>
        if @getInputVal() == ''
            @valModel.setVal(@min)
        else
            val = Number(@getInputVal())
            exception = @getValueException(val)
            if exception == 'min'
                @valModel.setVal(@min)
            else if exception == 'max'
                @valModel.setVal(@max)
            else if exception == 'int'
                @valModel.setVal(Math.floor(val))
        @mark_bad_input(false)
        @setInputVal(@getVal())

    plusOne: =>
        if @getInputVal() == ''
            @resetToValidValue()
        else
            val = Math.floor( Number(@getInputVal()) ) + 1
            @setVal(val)

    minusOne: =>
        if @getInputVal() == ''
            @resetToValidValue()
        else
            val = Math.ceil( Number(@getInputVal()) ) - 1
            @setVal(val)

    setVal: (val)=>
        if not isNaN(val)
            @setInputVal(val)
            @setValFromInput()
        @resetToValidValue()

    checkPastedText: (ev)=>
        setTimeout( ()=>
            re = if @float then /^(\d*\.?\d*)/ else /^(\d*)/
            validMatch = @getInputVal().match(re)
            val = if _.isEmpty(validMatch) then '' else validMatch[1]
            @setInputVal(val)
            @setValFromInput()
        )

    showSSDCapacity: (size, unit)=>
        $(@ui.unitLabel).text(unit)
        $(@ui.input).val(size)

    setDisabled: (bool)=>
        if not @disabled
            @ui.input.prop('disabled', bool)

    initialize: (options)=>
        @unit = options.unit
        @min = options.min ? 0
        @max = options.max
        @value = options.value
        @placeholder = options.placeholder
        @float = options.float
        @disabled = options.disabled
        @valModel = new ValueModel(val: @value)
        @listenTo(@valModel, 'change', @triggerChange)

    serializeData: =>
        return {
            min: @min,
            max: @max,
            value: @value,
            unit: @unit,
            disabled: @disabled,
            placeholder: @placeholder
        }

    keypress: (ev) =>
        key_codes = keyboard.press_key_codes
        keyCode = ev.which
        if keyCode == 0 or keyCode == 8
            keyCode = null
            #when firefox returns event.which='0', chrome returns 'null'
            #when firefox returns event.which='8' (backspace), chrome returns 'null'
        movement_key_codes = [
            key_codes.backspace
            key_codes.delete
            key_codes.arrow_left
            key_codes.arrow_right
            key_codes.tab
        ]
        action_key_codes = [
            key_codes.enter
            key_codes.escape
        ]
        if @filter_input(keyCode) and keyCode not in movement_key_codes.concat(action_key_codes)
            ev.preventDefault()

    keydown: (ev)=>
        key_codes = keyboard.down_key_codes
        keyCode = ev.which
        if keyCode == key_codes.arrow_up
            ev.preventDefault()
            @plusOne()
        if keyCode == key_codes.arrow_down
            ev.preventDefault()
            @minusOne()

    filter_input: (keyCode) =>
        if @isDigit(keyCode)
            return false
        if @float and @isDotValidForFloat(keyCode)
            return false
        return true

    isDigit: (keyCode) =>
        key_codes = keyboard.press_key_codes
        return (keyCode >= key_codes.zero and keyCode <= key_codes.nine)

    isDotValidForFloat: (keyCode) =>
        key_codes = keyboard.press_key_codes
        return (keyCode == key_codes.dot) and (@getInputVal().indexOf('.') == -1)

    mark_bad_input: (bool)=>
        @ui.input.toggleClass('bad_input', bool)


module.exports = NumberInput