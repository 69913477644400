switchTmpl = require('./tmpl/switchInput.hbs')
capacityTmpl = require('./tmpl/capacityInput.hbs')
radioTmpl = require('./tmpl/radio.hbs')
checkboxTmpl = require('./tmpl/checkboxInput.hbs')
inputTmpl = require('./tmpl/input.hbs')
DropDownMenu = require('./dropdown.coffee')
NumberInput = require('./number_input.coffee')
strings = require('../../framework/strings.ecmascript')
consts = require('../../framework/consts.ecmascript')

class SwitchInput extends Marionette.ItemView
    template: switchTmpl
    className: 'switch_input_wrapper input_wrapper formatRow'
    ui:
        input: '.switch'
    events:
        'change @ui.input': 'triggerChange'

    getVal: ()=>
        return @ui.input.prop('checked')

    setVal: (val)=>
        oldVal = @ui.input.prop('checked')
        @ui.input.prop('checked', val)
        if oldVal != val
            @triggerChange()

    triggerChange: ()=>
        @trigger('change')

    initialize: (options)=>
        @leftLabel = options.leftLabel
        @rightLabel = options.rightLabel
        @value = options.value
        @name = options.name

    serializeData: =>
        return {
            leftLabel: @leftLabel,
            rightLabel: @rightLabel,
            checked: @value,
            name: @name
        }

class CapacityInput extends Marionette.LayoutView
    template: capacityTmpl
    className: 'input_wrapper capacity_input formatRow'
    regions:
        dropdownRegion: '.dropdown_wrapper'
        numberInputRegion: '.number_input_wrapper'

    unitChange:()=>
        @unit = @getUnit()
        capacity_val = @getSize()
        if capacity_val <= 0
            return
        if @unit == 'tb'
            @numberInput.unitChange('/')
        else
            @numberInput.unitChange('*')

    triggerChange: ()=>
        @trigger('change')

    getUnit: ()=>
        return @unitsDropdown.getVal()

    getSize: ()=>
        return @numberInput.getVal()

    initialize: (options)=>
        @min = options.min
        @max = options.max
        @unit = options.unit
        @value = options.value
        @float = options.float

    onRender: ()=>
        @createNumberInput()
        @createUnitsDropdown()

    createUnitsDropdown: ()=>
        ddOptions = [
            {
                text: strings.TB()
                value: 'tb'
            },
            {
                text: strings.GB()
                value: 'gb'
            }
        ]
        @unitsDropdown = new DropDownMenu(options: ddOptions, value: @unit, class: 'capacity')
        @listenTo(@unitsDropdown, 'change', @unitChange)
        @dropdownRegion.show(@unitsDropdown)

    createNumberInput: =>
        @numberInput = new NumberInput(
            min: @min
            max: @max
            value: @value
            float: @float
        )
        @listenTo(@numberInput, 'change', @triggerChange)
        @numberInputRegion.show(@numberInput)

class RadioInput extends Marionette.ItemView
    template: radioTmpl
    className: 'input_wrapper formatRow'
    ui:
        bw: '#radio_BW'
        iops: '#radio_IOPs'
        input: 'input'
    events:
        'change @ui.input': 'triggerChange'

    triggerChange: (e)=>
        @trigger('change', e.target.value)

    getVal: ()=>
        if @ui.bw.prop('checked')
            return @ui.bw.val()
        return @ui.iops.val()

    initialize: (options)=>
        @options = options.options
        @name = options.name
        @selected = _.find(@options, (radioOption)-> radioOption.value == options.value)
        if @selected
            @selected.checked = true
        else
            options.options[0].checked = true

    serializeData: =>
        return {
            options: @options,
            name: @name
        }

class CheckboxInput extends Marionette.ItemView
    template: checkboxTmpl
    className: 'input_wrapper formatRow ' + (if window.IS_HP then 'sizer_input_wrapper' else '')
    ui:
        colorNotification: '.change_color'
        input: '.checkbox'
    events:
        'change @ui.input': 'triggerChange'
        'click @ui.input': 'triggerClick'

    changeText: (text)=>
        @ui.colorNotification.text(text)
        @ui.colorNotification.addClass('colorNote')
        setTimeout( ()=>
            @ui.colorNotification.removeClass('colorNote')
        , consts.colorNoteTimeout)



    triggerChange: ()=>
        @trigger('change')

    triggerClick: (event)=>
        @trigger('click', event.target.checked)

    getVal: ()=>
        return @ui.input.prop('checked')

    initialize: (options)=>
        @disabled = options.disabled
        @name = options.name
        @label = options.label
        @subLabel = options.subLabel
        @spanText = options.changingText
        @preSpanText = options.preChangingText
        @postSpanText = options.postChangingText
        @value = options.value

    serializeData: =>
        return {
            disabled: @disabled,
            name: @name,
            label: @label,
            subLabel: @subLabel,
            spanText: @spanText,
            preSpanText: @preSpanText,
            postSpanText: @postSpanText,
            checked: @value
        }

class Input extends Marionette.ItemView
    template: inputTmpl
    className: 'input'
    ui:
        input: '.input'
    events:
        'input @ui.input': 'setInputVal'

    initialize: (options)=>
        @value = options.value
        @placeholder = options.placeholder

    getInputVal: ()=>
        return @ui.input.val()

    setInputVal: (e)=>
        @ui.input.val(e.target.value)

    serializeData: =>
        return {
            value: @value,
            placeholder: @placeholder
        }

class InputsFactory
    @createInput: (options)=>
        if not options?.type?
            return
        if options.type == 'switch'
            return new SwitchInput(options)
        if options.type == 'number'
            return new NumberInput(options)
        if options.type == 'capacity'
            return new CapacityInput(options)
        if options.type == 'radio'
            return new RadioInput(options)
        if options.type == 'dropdown'
            return new DropDownMenu(options)
        if options.type == 'checkbox'
            return new CheckboxInput(options)

module.exports = {
    InputsFactory: InputsFactory,
    CheckboxInput: CheckboxInput,
    Input: Input
}
