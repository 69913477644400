consts = require('./consts.ecmascript')

getRegionName = (region)=>
    for option in consts.regionOptions
        if option.value == region
            return option.text

request_with_retry = (req_func, maxRetries = 2, exp_backoff = true) ->
    deferred = $.Deferred()
    send_req = (req_func, count) ->
        timeout = if exp_backoff then (2 ** (count+1) * 1000) else (1000 * (count+1))
        retry = ()->
            setTimeout( ()->
                send_req(req_func, count+1)
            , timeout)
        req_deferred = req_func()
        if count < maxRetries
            req_deferred.done(deferred.resolve)
            req_deferred.fail( (res) ->
                if res.status in [500, 503, 0]
                    retry()
                else
                    deferred.reject(res)
            )
        else
            req_deferred.done(deferred.resolve)
            req_deferred.fail(deferred.reject)
    send_req(req_func, 0)
    return deferred

post = (url, data, headers) =>
    return $.ajax(method: 'post', url: url, data: JSON.stringify(data), headers: headers, contentType: "application/json", dataType: "json",)

get = (url, data, headers) =>
    return $.ajax(method: 'get', url: url, data: data, headers: headers, contentType: "application/json", dataType: "json")


module.exports = {
    getRegionName: getRegionName
    request_with_retry: request_with_retry
    post: post
    get: get
}