noteTmpl = require('./tmpl/cluster_config_note.hbs')
clusterConfTmpl = require('./tmpl/cluster_config.hbs')
clientsConfigTmpl = require('./tmpl/clients_config.hbs')
BEConfigTmpl = require('./tmpl/backend_config.hbs')
defaultVersionTmpl = require('./tmpl/default_version.hbs')
strings = require('../framework/strings.ecmascript')
Inputs = require('../widgets/inputs/inputs.coffee')
InputsFactory = Inputs.InputsFactory
Loader = require('../widgets/misc/common.coffee').Loader
DeployButton = require('../widgets/misc/common.coffee').DeployButton
utils = require('../framework/utils.coffee')
consts = require('../framework/consts.ecmascript')

class BackendConfig extends Marionette.LayoutView
    className: 'BackendConfig'
    template: BEConfigTmpl

    regions:
        typeRegion: '.BETypeRegion'
        countRegion: '.BECountRegion'

    types: consts.backendTypes

    onRender: ()=>
        typeOptions = _.map(@types, (type)-> return {value: type, text: type})
        @typeInput = new InputsFactory.createInput(type: 'dropdown', options: typeOptions, value: @model.BEType, class: 'backends type')
        @listenTo(@typeInput, 'change', @typeInputChange)
        @typeRegion.show(@typeInput)

        countList = Array.apply(null, {length: 295})
        countOptions = _.map(countList, (object, index)-> return {value: index + 6, text: index + 6})
        @countInput = new InputsFactory.createInput(type: 'dropdown', options: countOptions, value: @model.BECount, class: 'backends count')
        @listenTo(@countInput, 'change', @countInputChange)
        @countRegion.show(@countInput)

    typeInputChange: ()=>
        @model.BEType = @typeInput.getVal()

    countInputChange: ()=>
        @model.BECount = @countInput.getVal()

    show: ()=>
        @.$el.removeClass('hidden')

    hide: ()=>
        @.$el.addClass('hidden')

    initialize: (options)=>
        @model = options.model

class ClientsConfig extends Marionette.LayoutView
    className: 'ClientsConfig'
    template: clientsConfigTmpl
    ui:
        customAmiInput: '.customAmi'
        customAmiWrapper: '.customAmiWrapper'
    regions:
        typeRegion: '.clientsTypeRegion'
        countRegion: '.clientsCountRegion'
        amiTypeRegion: '.amiTypeRegion'

    types: consts.clientTypes

    amiTypeOptions: [
        {
            divider: true
            text: strings.DEFAULT_AMI_TITLE()
        },
        {
            value: 'default'
            text: 'Amazon Linux'
        },
        {
            divider: true
            text: strings.CUSTOM_AMI_TITLE()
        },
        {
            value: 'custom'
            text: strings.CUSTOM_AMI_LABEL()
        }
    ]

    onRender: ()=>
        typeOptions = _.map(@types, (type)-> return {value: type, text: type})
        @typeInput = new InputsFactory.createInput(type: 'dropdown', options: typeOptions, value: @model.clientsType, class: 'clients type')
        @listenTo(@typeInput, 'change', @typeInputChange)
        @typeRegion.show(@typeInput)

        countList = Array.apply(null, {length: 50})
        countOptions = _.map(countList, (object, index)-> return {value: index + 1, text: index + 1})
        @countInput = new InputsFactory.createInput(type: 'dropdown', options: countOptions, value: @model.clientsCount, class: 'clients count')
        @listenTo(@countInput, 'change', @countInputChange)
        @countRegion.show(@countInput)

        @amiTypeInput = new InputsFactory.createInput(type: 'dropdown', options: @amiTypeOptions, value: @model.ami, class: 'clients amiType')
        @listenTo(@amiTypeInput, 'change', @amiTypeChange)
        @amiTypeRegion.show(@amiTypeInput)
        @amiTypeChange()

        @ui.customAmiInput.val(@model.customAmi)
        @ui.customAmiInput.on('input', @customAmiChange)

    typeInputChange: ()=>
        @model.clientsType = @typeInput.getVal()

    countInputChange: ()=>
        @model.clientsCount = @countInput.getVal()

    amiTypeChange: ()=>
        @model.ami = @amiTypeInput.getVal()
        if @model.ami == 'default'
            @ui.customAmiWrapper.addClass('hidden')
        else
            @ui.customAmiWrapper.removeClass('hidden')

    customAmiChange: ()=>
        @model.customAmi = @ui.customAmiInput.val()

    show: ()=>
        @.$el.removeClass('hidden')

    hide: ()=>
        @.$el.addClass('hidden')

    initialize: (options)=>
        @model = options.model

class ClusterNote extends Marionette.ItemView
    className: 'ClusterNote'
    template: noteTmpl

    ui:
        icon: '.fa'
        note: '.errorLine'

    colorNote: ()=>
        @ui.icon.addClass('colorNote')
        @ui.note.addClass('colorNote')
        setTimeout( ()=>
            if @isRendered
                @ui.icon.removeClass('colorNote')
                @ui.note.removeClass('colorNote')
        , consts.colorNoteTimeout)

    onRender: ()=>
        if @colorNotification
            @colorNote()

    initialize: (options)=>
        @text = options.text
        @colorNotification = options.colorNotification

    serializeData: =>
        return {
            text: @text
        }

class DefaultVersion extends Marionette.ItemView
    className: 'DefaultVersion'
    template: defaultVersionTmpl
    ui:
        button: '.change'
    events:
        'click @ui.button': 'showOlderVersions'

    showOlderVersions: ()=>
        @trigger('olderVersions')

    initialize: (options)=>
        @versionName = options.version.id

    serializeData: =>
        return {version: @versionName}

class ClusterConfView extends Marionette.LayoutView
    className: 'ClusterConfig'
    tagName: 'section'
    template: clusterConfTmpl
    regions:
        trunksRegion: '.trunksRegion'
        versionsRegion: '.versionsRegion'
        betaNoteRegion: '.betaNoteRegion'
        backendCheckboxRegion: '.backendCheckboxRegion'
        backendConfigRegion: '.backendConfigRegion'
        clientsCheckboxRegion: '.clientsCheckboxRegion'
        clientsConfigRegion: '.clientsConfigRegion'
        deploymentNoteRegion: '.deploymentNoteRegion'
        clientsNoteRegion: '.clientsNoteRegion'
        buttonRegion: '.buttonRegion'
    ui:
        trunks: '.trunksRow'
        deployText: '.smallText'
        changeRegionButton: '.changeRegion'
    events:
        'click @ui.changeRegionButton': 'changeRegion'

    onRender: ()=>
        if @model.determinedVersion
            @showDefaultVersion()
        else
            @showOlderVersions()

        if @wekaUser
            @customBEInput = new InputsFactory.createInput(name: 'customBE', type: 'checkbox', label: 'Change backend instances (total cost will vary)', value: @model.customBE)
            @listenTo(@customBEInput, 'change', @costumBEChange)
            @backendCheckboxRegion.show(@customBEInput)
            @BEConfig = new BackendConfig(model: @model)
            @backendConfigRegion.show(@BEConfig)
            @costumBEChange()

        @withClientsInput = new InputsFactory.createInput(name: 'withClients', type: 'checkbox', label: 'Add clients to cluster', value: @model.withClients)
        @listenTo(@withClientsInput, 'change', @withClientsChange)
        @clientsCheckboxRegion.show(@withClientsInput)


        @clientsConfig = new ClientsConfig(model: @model)
        @clientsConfigRegion.show(@clientsConfig)

        @withClientsChange(true)

        @deployButton = new DeployButton()
        @listenTo(@deployButton, 'click', @deploy)
        @buttonRegion.show(@deployButton)

        @deploymentNote = new ClusterNote(text: strings.DEPLOYMENT_CONNECTIVITY_NOTE())
        @deploymentNoteRegion.show(@deploymentNote)
        @ui.deployText.html(strings.PRE_DEPLOY_TEXT())

    showOlderVersions: ()=>
        @showVersionsLoader()
        trunksDeferred = @versionsModel.FetchAllTrunks()
        versionsDeferred = @versionsModel.FetchAllVersions(@model.selectedTrunk)
        $.when(versionsDeferred, trunksDeferred).done ()=>
            @model.determinedVersion = false
            @model.selectedTrunk ?= @versionsModel.publicTrunkId
            if @versionsModel.trunks.length > 1
                @ui.trunks.removeClass('hidden')
                if not @trunkOptions?
                    @createTrunkOptions()
                @trunksInput = new InputsFactory.createInput(type: 'dropdown', options: @trunkOptions, value: @model.selectedTrunk, class: 'versions', maxWidth: 370)
                @listenTo(@trunksInput, 'change', @trunkChange)
                @trunksRegion.show(@trunksInput)
            @resetVersionsInput()
            @updateBetaNote()
        fetchFailure = (res) =>
            @showVersionsError(res.responseText)
            @showDefaultVersion()
        versionsDeferred.fail (fetchFailure)
        trunksDeferred.fail (fetchFailure)

    showDefaultVersion: ()=>
        @model.version = @default_version.id
        @versionView = new DefaultVersion(version: @default_version)
        @listenTo(@versionView, 'olderVersions', @showOlderVersions)
        @versionsRegion.show(@versionView)

    versionChange: ()=>
        @model.version = @versionsInput.getVal()
        @updateBetaNote()

    showVersionsLoader: =>
        @versionsRegion.show(new Loader(type: 'versions'))

    trunkChange: ()=>
        prevTrunk = @model.selectedTrunk
        @model.selectedTrunk = @trunksInput.getVal()
        @showVersionsLoader()
        versionsDeferred = @versionsModel.FetchAllVersions(@model.selectedTrunk)
        versionsDeferred.done ()=>
            @resetVersionsInput()
            @versionChange()
        versionsDeferred.fail (res)=>
            @model.selectedTrunk = prevTrunk
            @trunksInput.setVal(prevTrunk)
            @showVersionsError(res.responseText)

    showVersionsError: (msg)=>
        @reportError(strings.VERSION_FETCH_FAILURE_TITLE(), msg)

    resetVersionsInput: ()=>
        versionOptions = @createVersionOptions(@model.selectedTrunk)
        selected = _.find(versionOptions, (opt)=> return opt.value == @model.version)
        selected ?= _.find(versionOptions, (opt)-> return not opt.beta)
        selected ?= versionOptions[0]
        @versionsInput = new InputsFactory.createInput(type: 'dropdown', options: versionOptions, value: selected.value, class: 'versions', maxWidth: 370)
        @listenTo(@versionsInput, 'change', @versionChange)
        @versionsRegion.show(@versionsInput)

    changeRegion: ()=>
        @trigger('change_region')

    withClientsChange: (auto)=>
        @model.withClients = @withClientsInput.getVal()
        if @model.withClients
            @clientsConfig.show()
        else
            @clientsConfig.hide()
        @updateClientsNote(auto)

    costumBEChange: ()=>
        @model.customBE = @customBEInput.getVal()
        if @model.customBE
            @BEConfig.show()
        else
            @BEConfig.hide()

    updateBetaNote: ()=>
        selectedVersion = _.find(@versions, (version) => return version.id == @model.version)
        if not selectedVersion.public
            @betaNote = new ClusterNote(text: strings.BETA_VERSION_NOTE(), colorNotification: true)
            @betaNoteRegion.show(@betaNote)
        else if selectedVersion != @default_version
            @betaNote = new ClusterNote(text: strings.OLD_VERSION_NOTE(), colorNotification: true)
            @betaNoteRegion.show(@betaNote)
        else
            @betaNoteRegion.reset()

    updateClientsNote: (auto)=>
        if @model.withClients
            @clientsNote = new ClusterNote(text: strings.DEPLOYMENT_CLIENTS_NOTE(), colorNotification: not auto)
            @clientsNoteRegion.show(@clientsNote)
        else
            @clientsNoteRegion.reset()

    reportError: (title, msg)=>
        @trigger('showError', title, msg)

    deploy: ()=>
        clientsConfig = @model.getClientsConfig()
        if @wekaUser and @model.customBE
            BEConfig = @model.getBEConfig()
        else
            BEConfig = {
                role: "backend",
                instance_type: @clusterModel.getBEType()
                count: @clusterModel.getBECount()
            }
        reqData = {
            cluster: [BEConfig, clientsConfig]
        }

        @ui.deployText.html(strings.POST_DEPLOY_TEXT())
        deferred = $.Deferred()
        @setButtonDeferred(deferred)
        deferred.done () =>
            if not @isRendered
                return
            setTimeout( ()=>
                @ui.deployText.html('')
                setTimeout( ()=>
                    window.location = @redirectURL
                , 400)
            , 400)
        deferred.fail (error) =>
            if not @isRendered
                return
            @ui.deployText.html(strings.PRE_DEPLOY_TEXT())
            @reportError(strings.GET_CFN_URL_FAILURE_TITLE(), strings.GET_CFN_URL_FAILURE_MSG(error: error.responseText))
        @getCfnTemplate(reqData, deferred)

    getCfnTemplate: (reqData, deferred)=>
        headers = {'Authorization': localStorage.getItem('accessTokenType') + ' ' + localStorage.getItem('accessToken')}
        req_func = () => return utils.post(consts.releasesBucketURL + consts.releasesAPIPaths.cfn + @model.version, reqData, headers)
        utils.request_with_retry(req_func, 1)
            .done (data)=>
                if @region in _.keys(data.quick_create_stack)
                    @redirectURL = data.quick_create_stack[@region]
                    deferred.resolve()
                else
                    deferred.reject()
            .fail (error)=>
                deferred.reject(error)
                return

    hasDeferred: =>
        return @deployButton.hasDeferred()

    setButtonDeferred: (deferred) =>
        @deployButton.setDeferred(deferred)

    createVersionOptions: (trunkId)=>
        versionOptions = []
        @versions = @versionsModel.versionsByTrunk[trunkId]
        for version in @versions
            option = {}
            option.value = version.id
            option.text = version.id
            option.beta = not version.public
            if version == @default_version
                option.text = option.text.concat(strings.DEFAULT_VERSION_SUFFIX())
            versionOptions.push(option)
        return versionOptions

    createTrunkOptions: () =>
        @trunkOptions = []
        for trunk in @versionsModel.trunks
            option = {}
            option.value = trunk.id
            option.text = trunk.display_name
            @trunkOptions.push(option)

    initialize: (options)=>
        @wekaUser = options.wekaUser
        @model = options.model
        @clusterModel = options.cluster
        @region = @clusterModel.getRegion()
        @versionsModel = options.versionsModel
        @default_version = @versionsModel.defaultVersion

    serializeData: =>
        return {
            wekaUser: @wekaUser
            region: utils.getRegionName(@region)
        }

module.exports = ClusterConfView
