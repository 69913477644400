buttonsTmpl = require('./tmpl/contact_buttons.hbs')
deployButtonTmpl = require('./tmpl/deploy_button.hbs')
ButtonTmpl = require('./tmpl/button.hbs')
consts = require('../../framework/consts.ecmascript')
strings = require('../../framework/strings.ecmascript')
sizerStrings = require('../../framework/sizerStrings.ecmascript');


class ContactButtonsView extends Marionette.ItemView
    className: "ContactButtonsView"
    template: buttonsTmpl
    ui:
        contactUs: '.buttonContactUs'
        docsLink: '.buttonDocs'
    events:
        'click @ui.contactUs': 'showContactUsForm'
        'click @ui.docsLink': 'goToDocs'

    showContactUsForm: ()=>
        @trigger('contact_us')

    goToDocs: ()=>
        @trigger('go_to_docs')


class ButtonView extends Marionette.ItemView
    className: () => return "ButtonView #{@constructor.extraClass}"
    tagName: 'button'
    attributes: {tabindex: '-1'}
    template: ButtonTmpl
    events:
        'click': 'triggerClick'

    triggerClick: ()=>
        @trigger('click')

    setDeferred: (deferred) =>
        @showLoader()
        deferred.always ()=>
            @hideLoader()

    showLoader: =>
        @.$el.addClass('has_deferred')

    hideLoader: =>
        @.$el.removeClass('has_deferred')

    hasDeferred: =>
        return @.$el.hasClass('has_deferred')

    initialize: (options)=>
        @text = options.text
        @text ?= @constructor.text

    serializeData: =>
        return
            text: @text
            retry: @type == 'retry'


class DeployButton extends ButtonView
    @extraClass: 'DeployButton'
    @text: if window.IS_HP then sizerStrings.CREATE_BOM_BUTTON() else strings.DEPLOY_TO_AWS()


class ElipsButton extends ButtonView
    @extraClass: 'ElipsButton'

    initialize: (options)=>
        super(options)
        @type = options.type

    onRender: =>
        if @type == 'submit'
            @.$el.addClass('type_submit')
        if @type == 'retry'
            @.$el.addClass('type_retry')
        if @type == 'continue'
            @.$el.addClass('type_continue')
        if @type == 'no_action'
            @.$el.addClass('type_no_action')


class Loader extends Marionette.ItemView
    template: '<div></div>'
    className: "loader"

    initialize: (options)=>
        @.$el.addClass(options.type)


module.exports = {
    ContactButtonsView: ContactButtonsView,
    DeployButton: DeployButton,
    ElipsButton: ElipsButton,
    Loader: Loader
}
