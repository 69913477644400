models = require('../main/models.ecmascript')
strings = require('../framework/strings.ecmascript')
ParamsCollection = models.ParamsCollection
Param = models.Param
RequirementsModel = models.RequirementsModel
requirements = require('../requirements/requirements.coffee')
helpPopups = require('../widgets/help_popups/help_popups.coffee')
Input = require('../widgets/inputs/inputs.coffee').Input
CheckboxInput = require('../widgets/inputs/inputs.coffee').CheckboxInput
paramTmpl = require('./tmpl/param.hbs')
debugConsoleTmpl = require('./tmpl/debugConsole.hbs')
clusterOptionTmpl = require('./tmpl/cluster_option.hbs')
DeployButton = require('../widgets/misc/common.coffee').DeployButton


class ParamView extends Marionette.LayoutView
    className: if window.IS_HP then 'ParamView SizerParamView' else 'ParamView'
    template: paramTmpl
    ui:
        helpIcon: '.help_icon'
        value: '.paramValue'
    regions:
        helpRegion: '.help_icon'
        tooltipHelpRegion: '.tooltipHelp'
    events:
        'click @ui.helpIcon': 'helpClick'
        'click @ui.value': 'tooltipHelpClick'

    tooltipHelpClick: (e)=>
        if @isHeader or not @tooltipHelp
            return
        e.stopPropagation()
        if @helpPopup?
            @dismissHelp()
        else
            @showHelp(true)

    helpClick: (e)=>
        e.stopPropagation()
        if @helpPopup?
            @dismissHelp()
        else
            @showHelp(false)

    dismissHelp: ()=>
        if @helpPopup?
            @helpPopup = undefined
            @activePopupRegion.reset()
            @trigger('change:helpShown', undefined)

    showHelp: (tooltip)=>
        if tooltip
            @helpPopup = new helpPopups.HelpPopup(helpText: @tooltipHelp, extraClass: 'internalPopup')
            @activePopupRegion = @tooltipHelpRegion
        else
            if @isPerformanceTitle()
                perfNote = if @isPerfRequired then strings.PERFORMANCE_BENCHMARKS_NOTE() else strings.PERFORMANCE_BENCHMARKS_NOTE_NO_PERF_REQ()
                @helpPopup = new helpPopups.HelpPopup(helpText: perfNote, extraClass: 'headerNotePopup')
            else
                @helpPopup = new helpPopups.ParamHelpPopup(help: @helpOptions, cluster: @cluster)
            @listenTo(@helpPopup, 'change:helpShown', @triggerHelpShown)
            @activePopupRegion = @helpRegion

        @listenTo(@helpPopup, 'dismiss', @dismissHelp)
        @activePopupRegion.show(@helpPopup)
        @triggerHelpShown(@helpPopup)

    triggerHelpShown: (popup) =>
        @trigger('change:helpShown', popup)

    getWidth: ()=>
        baseWidth = @model.getBaseWidth()
        if @hasHelp
            return baseWidth + 25
        if window.IS_HP
            return baseWidth + 50
        return baseWidth

    hasNoData: =>
        return @model.getValue() == @cluster.noDataString

    isPerformanceParam: =>
        return @model.getKey() in ['iops', 'bw']

    isPerformanceTitle: =>
        return @isHeader and @isPerformanceParam()

    isWithHelpIcon: =>
        if @isPerformanceTitle() && !window.IS_HP
            return true
        if window.IS_HP and @model.getKey() == "weka_total_cost" and not @model.attributes.display_cost and not @isHeader and @hasNoData()
            return false
        return @hasHelp and not @isHeader and not @hasNoData()

    initialize: (options)=>
        @isPerfRequired = options.isPerfRequired
        @isHeader = @model.isHeader()
        @helpOptions = @model.getInfo()
        @hasHelp = @helpOptions?
        @cluster = @model.getCluster()
        getMouseClickGetter = @model.getMouseClickGetter()
        @tooltipHelp = if @isHeader or not getMouseClickGetter? then undefined else @cluster[getMouseClickGetter]()
        if @isWithHelpIcon() and not @isPerformanceTitle()
            @helpOptions.colKey = @model.getKey()
            if @cluster.get(@helpOptions.more_info?.key)?
                @helpOptions.moreInfoLabel = @helpOptions.more_info.label
                @helpOptions.moreInfoText = @cluster.getFormattedValue(@helpOptions.more_info.key)

    onRender: ()=>
        @.$el.width(@getWidth())

    serializeData: =>
        if @isPerformanceParam() and not @isHeader
            preValueText = if window.IS_HP then '' else 'Up to '
        return {
            tooltipHelp: @tooltipHelp
            help: if @isWithHelpIcon() then true else 0
            preValueText: preValueText
            value: @model.getValue()
            bold: @model.isBold()
        }

class ParamsCollectionView extends Marionette.CollectionView
    childView: ParamView
    className: 'ParamsCollection'

    childViewOptions: (child, index)=>
        return {
            isPerfRequired: @isPerfRequired
        }

    childEvents:
        'change:helpShown': 'triggerHelpShown'

    triggerHelpShown: (child, popup)=>
        @trigger('change:helpShown', popup)

    initialize: (options)=>
        @isPerfRequired = options.isPerfRequired


class ClusterOptionView extends Marionette.LayoutView
    template: clusterOptionTmpl
    className: if window.IS_HP then 'ClusterOption SizerClusterOption' else 'ClusterOption'
    regions:
        paramsRegion: '.paramsWrapper'
        buttonRegion: '.deployWrapper'
        selectRegion: '.selectWrapper'
        commentRegion: '.commentWrapper'

    deployCluster: ()=>
        @trigger('deploy', @model)

    toggle: ()=>
        @.$el.css('display', if @.$el.css('display') == 'block' then 'none' else 'block')

    deselect: =>
        @deployButton.hideLoader()

    setZ: (collectionLength)=>
        z = 2 + collectionLength - @index
        @.$el.css('z-index', z)

    onRender: ()=>
        params = []
        for column in @columns
            paramAttrs = {}
            for key in _.keys(column)
                paramAttrs[key] = column[key]
            paramAttrs.value = @model.getFormattedValue(column.key, undefined ,column.conversion)
            paramAttrs.baseWidth = @colWidth
            paramAttrs.cluster = @model
            param = new Param(paramAttrs)
            params.push(param)
        @paramsCollection = new ParamsCollection(params)
        @paramsCollectionView = new ParamsCollectionView(collection: @paramsCollection)
        @listenTo(@paramsCollectionView, 'change:helpShown', @triggerHelpShown)
        @paramsRegion.show(@paramsCollectionView)
        if !window.IS_HP || @model.attributes.bom_dialog
            @deployButton = new DeployButton()
            @listenTo(@deployButton, 'click', if window.IS_HP then @triggerOpenCreateBomModal else @deployCluster)
            @buttonRegion.show(@deployButton)
        if window.IS_HP and @withSelect
            @checkbox = new CheckboxInput()
            @listenTo(@checkbox, 'click', (checked)=>@optionSelected(checked, @model))
            @selectRegion.show(@checkbox)

    optionSelected: (checked, option) =>
        action = if checked then 'optionAddedToExport' else 'optionRemovedFromExport'
        if checked
            @input = new Input(placeholder: 'Comment')
            @commentRegion.show(@input)
        else
            @commentRegion.empty({preventDestroy: true})
        @trigger(action, option)

    setButtonDeferred: (deferred)=>
        @deployButton.setDeferred(deferred)

    triggerHelpShown: (popup)=>
        @trigger('change:helpShown', popup)
    
    triggerOpenCreateBomModal: ()=>
        @trigger('openCreateBomModal', @model.attributes)

    initialize: (options)=>
        @withSelect = options.withSelect
        @index = options.index
        @columns = options.columns
        @colWidth = options.colWidth


class ClusterOptionsCollectionView extends Marionette.CollectionView
    childView: ClusterOptionView
    className: 'ClustersCollection'
    childViewOptions: (child, index)=>
        return {
            index: index
            columns: @columns
            colWidth: @colWidth
            withSelect: @withSelect
        }
    childEvents:
        'change:helpShown': 'triggerHelpShown'
        'deploy': 'deployCluster'
        'openCreateBomModal': 'triggerOpenCreateBomModal'
        'optionAddedToExport': 'triggerOptionAddedToExport'
        'optionRemovedFromExport': 'triggerOptionRemovedFromExport'

    deployCluster: (child)=>
        @chosenOption?.deselect()
        @chosenOption = child
        @trigger('deploy')

    getChosenOption: =>
        return @chosenOption

    triggerHelpShown: (child, popup)=>
        @trigger('change:helpShown', popup)

    triggerOpenCreateBomModal: (e, data)=>
        @trigger('openCreateBomModal', data)

    triggerOptionAddedToExport: (option)=>
        @trigger('optionAddedToExport', option)

    triggerOptionRemovedFromExport: (option)=>
        @trigger('optionRemovedFromExport', option)

    toggleVendor: (vendor) =>
        @children.each( (child)=>
            if child.model.attributes.vendor == vendor
                child.toggle()
        )

    onRender: ()=>
        selectedVendors = localStorage.getItem('selectedVendors')
        parsedSelectedVendors = new Set(JSON.parse(selectedVendors))

        optionsCounter = 0
        @children.each( (child)=>
            child.setZ(@children.length)
            if window.IS_HP && _.includes(['true', null], localStorage.getItem('firstCapacityTabActive')) &&
            selectedVendors && !parsedSelectedVendors.has(child.model.attributes.vendor)
                child.$el.css('display', 'none')
            
            optionsCounter = optionsCounter + 1
            if @showOnly3Options && optionsCounter > 3
                child.$el.css('display', 'none')
        )

    initialize: (options)=>
        @withSelect = options.withSelect
        @showOnly3Options = options.showOnly3Options
        @collection = options.collection
        @columns = options.colsDescription
        @colWidth = ClusterOptionsCollectionView.getColWidth(@columns)

    @getColWidth: (columns)=>
        deployButtonSpace = 200
        maxWidth = 110
        totalWidth = 810 - 10 - deployButtonSpace
        helpWidth = 25
        helpAmount = 0
        for column in columns
            if column.question_mark_info?
                helpAmount += 1
        return Math.min(maxWidth, Math.floor( (totalWidth - (helpWidth * helpAmount)) / columns.length))

class OptionsHeaderView extends Marionette.LayoutView
    className: if window.IS_HP then 'ClusterOption headerRow SizerClusterOption' else 'ClusterOption headerRow'
    template: clusterOptionTmpl
    regions:
        paramsRegion: '.paramsWrapper'

    triggerHelpShown: (popup)=>
        @trigger('change:helpShown', popup)

    onRender: ()=>
        params = []
        for column in @columns
            column.baseWidth = @colWidth
            column.isHeader = true
            column.value = column.title
            param = new Param(column)
            params.push(param)
        @paramsCollection = new ParamsCollection(params)
        @paramsCollectionView = new ParamsCollectionView(collection: @paramsCollection, isPerfRequired: @isPerfRequired)
        @listenTo(@paramsCollectionView, 'change:helpShown', @triggerHelpShown)
        @paramsRegion.show(@paramsCollectionView)

    initialize: (options)=>
        @collection = options.collection
        @columns = options.colsDescription
        @colWidth = ClusterOptionsCollectionView.getColWidth(@columns)
        @isPerfRequired = options.isPerfRequired

    serializeData: =>
        return {header: true}


class DebugConsoleView extends Marionette.LayoutView
    template: debugConsoleTmpl

    initialize: (options)=>
        @debugLog = options.log

    serializeData: =>
        return {
            debugLog: @debugLog
        }


module.exports ={
    ClusterOptionsCollectionView: ClusterOptionsCollectionView,
    OptionsHeaderView: OptionsHeaderView,
    DebugConsoleView: DebugConsoleView
}
