contactUsTmpl = require('./tmpl/contactUs.hbs')
consts = require('../framework/consts.ecmascript')
strings = require('../framework/strings.ecmascript')
utils = require('../framework/utils.coffee')
common = require('../widgets/misc/common.coffee')

class ContactUsForm extends Marionette.LayoutView
    className: 'ContactUsForm'
    template: contactUsTmpl
    regions:
        buttonRegion: '.buttonRegion'
    ui:
        form: '.contactUsForm'
        nameInput: '.nameInput'
        emailInput: '.emailInput'
        companyInput: '.companyInput'
        phoneInput: '.phoneInput'
        msgInput: '.msgInput'
        copyInput: '#copy'
    events:
        'submit @ui.form': 'send'

    dismiss: ()=>
        @trigger('dismiss')

    send: (e)=>
        e.preventDefault()
        details = {
            name: @ui.nameInput.val(),
            email: @ui.emailInput.val(),
            company: @ui.companyInput.val(),
            phone: @ui.phoneInput.val(),
            message: @ui.msgInput.val().replace(/\n/g,"<br>"),
            send_copy: @ui.copyInput.prop("checked"),
            requirements: @reqsModel.getRequirements()
        }
        req_func = () => return utils.post(consts.APIs.contactUs, details)
        deferred = utils.request_with_retry(req_func)
            .done ()=>
                @dismiss()
            .fail (res)=>
                @reportError(res.responseText)
        @setButtonDeferred(deferred)

    reportError: (msg)=>
        @trigger('showError', strings.CONTACT_US_FAILURE_TITLE(), msg)

    onRender: =>
        @button = new common.ElipsButton(type: 'submit', text: 'Send')
        @buttonRegion.show(@button)

    hasDeferred: =>
        return @button.hasDeferred()

    setButtonDeferred: (deferred) =>
        @button.setDeferred(deferred)

    initialize: (options)=>
        @reqsModel = options.reqsModel

module.exports = ContactUsForm