consts = require('../../../framework/consts.ecmascript')
utils = require('../../../framework/utils.coffee')
partnerLoginTmpl = require('./PartnerLogin.hbs')

class PartnerLogin extends Marionette.ItemView
    template: partnerLoginTmpl
    ui:
        input: 'input'
        button: 'button'
    events:
        'click @ui.button': 'sendPartnerEmail'

    initialize: (options)=>
        @emaildomain = options.emaildomain
        @partnername = options.partnername
        @email = options.email

    onRender: =>
        loadGoogleSignIn = () ->               
            script = document.createElement('script')
            script.src = 'https://apis.google.com/js/platform.js'
            document.body.appendChild(script)
        setTimeout(loadGoogleSignIn, 500)

    sendPartnerEmail: ()=>
        utils.post(consts.APIs.token, {
            email: @ui.input.val() + '@' + @emaildomain,
            hostname: window.location.hostname,
            partnername: @partnername,
            email_url: consts.APIs.sendEmail
        })
        .done (response)=>
            if window.location.hostname != "localhost" 
                utils.post(consts.APIs.logger, {
                    event_type: 'registration',
                    hostname: window.location.hostname,
                    email: @ui.input.val() + '@' + @emaildomain,
                })
            alert("An email with access link was sent to your email address, please follow this link")

    serializeData: =>
        return {
            docsURL: consts.docsLinks.root,
            emaildomain: @emaildomain,
            partnername: @partnername,
            email: if @email then @email.split('@')[0] else false
        }

module.exports = PartnerLogin
