html2pdf = require('html2pdf.js');
calcTmpl = require('./tmpl/calc_section.hbs')
models = require('./models.ecmascript')
RequirementsModel = models.RequirementsModel
ParamsCollection = models.ParamsCollection
Param = models.Param
requirements = require('../requirements/requirements.coffee')
CapacityGroup = requirements.CapacityGroup
PerformanceGroup = requirements.PerformanceGroup
VendorsGroup = requirements.VendorsGroup
FiltersGroup = requirements.FiltersGroup
RegionGroup = requirements.RegionGroup
RegionNote = requirements.RegionNote

sizerRequirements = require('../sizer/requirements/requirements.coffee')
SizerCapacityGroup = sizerRequirements.CapacityGroup
SizerCustomerAndDealGroup = sizerRequirements.CustomerAndDealGroup

strings = require('../framework/strings.ecmascript')
sizerStrings = require('../framework/sizerStrings.ecmascript')
calcErrorTmpl = require('./tmpl/calcError.hbs')
ContactButtonsView = require('../widgets/misc/common.coffee').ContactButtonsView
common = require('../widgets/misc/common.coffee')
results = require('../results/results.coffee')
BOMModal = require('../sizer/results/BOMModal/BOMModal.coffee')
BOMModalView = BOMModal.BOMModalView
ClusterOptionsCollectionView = results.ClusterOptionsCollectionView
OptionsHeaderView = results.OptionsHeaderView
DebugConsoleView = results.DebugConsoleView
exportTemplate = require('../sizer/results/export/export.hbs')


class CalcError extends Marionette.LayoutView
    className: 'CalcError'
    template: calcErrorTmpl
    regions:
        buttonRegion: '.buttonRegion'

    tryAgain: ()=>
        @button.showLoader()
        @trigger('tryAgain')

    onRender: ()=>
        if @button?
            @buttonRegion.show(@button)
            @listenTo(@button, 'click', @tryAgain)

    initialize: (options)=>
        @title = options.title
        @msg = options.msg
        if options.tryAgain
            @button = new common.ElipsButton(type: 'retry', text: strings.TRY_AGAIN())

    serializeData: =>
        return{
            title: @title,
            msg: @msg
        }

class CalculatorSection extends Marionette.LayoutView
    template: calcTmpl
    className: if window.IS_HP then 'MainView SizerMainView' else 'MainView'
    ui:
        export: '.export'
        save: '.save'
        cancel: '.cancel'
        showButton: '.show-button'
    events:
        'click @ui.export': 'selectForExport'
        'click @ui.cancel': 'cancelExport'
        'click @ui.save': 'export'
        'click @ui.showButton': 'showButtonClicked'
    regions:
        optionsRegion: '.optionsWrapper'
        optionsHeaderRegion: '.optionsHeaderWrapper'
        optionsLoaderRegion: '.loaderRegion'
        capacityRegion: '.capacityGroup'
        performanceRegion: '.performanceGroup'
        customerAndDealRegion: '.customerAndDealGroup'
        filtersRegion: '.filtersGroup'
        vendorsRegion: '.vendorsGroup'
        regionRegion: '.regionGroup'
        noteRegion: '.noteRegion'
        debugConsoleRegion: '.debugConsole'
        BOMModalRegion: '.bom-modal'

    initialize: (options)=>
        @withSelect = false
        @showOnly3Options = !window.IS_HP
        @reqsModel = new RequirementsModel(options)
        @listenTo(@reqsModel, 'calcError', @showClusterError)
        @listenTo(@reqsModel, 'newOptions', @showClusterOptions)
        @calculate = _.debounce(@sendCalcRequest, 400)
        # Temporary disable debug
        # window.addEventListener("keypress", @toggleDebugConsole)

    onRender: ()=>
        if window.IS_HP
            @capacityGroup = new SizerCapacityGroup(reqsModel: @reqsModel)
        else
            @capacityGroup = new CapacityGroup(reqsModel: @reqsModel)
        @performanceGroup = new PerformanceGroup(reqsModel: @reqsModel)
        @reqsGroups = [@capacityGroup, @performanceGroup]

        @capacityRegion.show(@capacityGroup)
        @performanceRegion.show(@performanceGroup)

        if !window.IS_HP
            @regionGroup = new RegionGroup(reqsModel: @reqsModel)
            @regionRegion.show(@regionGroup)
            @reqsGroups.push(@regionGroup)
        else
            @customerAndDealGroup = new SizerCustomerAndDealGroup(reqsModel: @reqsModel)
            @filtersGroup = new FiltersGroup(reqsModel: @reqsModel)
            @reqsGroups.push(@customerAndDealGroup)
            @reqsGroups.push(@filtersGroup)
            @customerAndDealRegion.show(@customerAndDealGroup)
            @filtersRegion.show(@filtersGroup)


        @listenToGroups('change:helpShown', @updateShownTT)
        @listenToGroups('calc', @calculate)
        @sendCalcRequest()

    calculateTryAgain: =>
        @calculate(true)

    resetResults: ()=>
        @optionsHeaderRegion.reset()
        @optionsRegion.reset()

    sendCalcRequest: (noLoader)=>
        if not noLoader
            @showLoader()
        @reqsModel.sendCalcRequest()

    showClusterOptions: (collection, exactOption, error)=>
        @removeLoader()
        @relevantcollection = if _.includes(['true', null], localStorage.getItem('firstCapacityTabActive')) then collection else exactOption
        colsDescription = collection.getColsDescription()
        if window.IS_HP && !@relevantcollection.models[0].attributes.hasOwnProperty("weka_total_cost")
            colsDescription = colsDescription.filter((c) => c.key != "weka_total_cost")
        @clusters = new ClusterOptionsCollectionView(withSelect: @withSelect, showOnly3Options: @showOnly3Options, collection: @relevantcollection, colsDescription: colsDescription)
        @listenTo(@clusters, 'openCreateBomModal', @openBOMDialog)
        @listenTo(@clusters, 'change:helpShown', @updateShownTT)
        @listenTo(@clusters, 'deploy', @deployCluster)
        @listenTo(@clusters, 'optionAddedToExport', @optionAddedToExport)
        @listenTo(@clusters, 'optionRemovedFromExport', @optionRemovedFromExport)
        if error && localStorage.getItem('firstCapacityTabActive') == 'false'
            @calcError = new CalcError({title: error})
            @optionsRegion.show(@calcError)
        else
            @optionsRegion.show(@clusters)
        @optionsHeader = new OptionsHeaderView(collection: @relevantcollection, isPerfRequired: @relevantcollection.isPerfRequired, colsDescription: colsDescription)
        @listenTo(@optionsHeader, 'change:helpShown', @updateShownTT)
        @optionsHeaderRegion.show(@optionsHeader)
        if window.IS_HP
            @vendors = new Set(collection.models.map((o) => o.attributes.vendor))
            @ras = new Set(collection.models.map((o) => o.attributes))
            @toggleVendors()            
            @capacityGroup = new SizerCapacityGroup(reqsModel: @reqsModel, vendors: @vendors, ras: @ras)
            @capacityRegion.show(@capacityGroup)
            @listenTo(@capacityGroup, 'calc', @calculate)
            @listenTo(@capacityGroup, 'CapacityTabSwitched', @toggleVendors)
            # @log = collection.getLog()

    filterByVendors: (vendor) =>
        @clusters.toggleVendor(vendor)

    toggleVendors: () =>
        if _.includes(['true', null], localStorage.getItem('firstCapacityTabActive'))
            @vendorsGroup = new VendorsGroup(vendors: @vendors)
            @vendorsRegion.show(@vendorsGroup)
            @filtersRegion.show(@filtersGroup)
            @listenTo(@vendorsGroup, 'change', @filterByVendors)
        else
            @filtersRegion.empty({preventDestroy: true});
            @vendorsRegion.reset()

    openBOMDialog: (data)=>
        configValid = true
        if (data.enforce_priority)
            output = JSON.stringify(data.bom_dialog)
            if (output.match(/"SKU":/g) || []).length != (output.match(/"priority":/g) || []).length
                alert("There are some SKU priorities missing in your config")
                configValid = false
        if configValid
            @BOMModal = new BOMModalView(data)
            @listenTo(@BOMModal, 'closeDialog', @closeDialog)
            @BOMModalRegion.show(@BOMModal)
    
    closeDialog: () =>
        @BOMModalRegion.reset()

    deployCluster: ()=>
        @trigger('deploy')

    getChosenOption: =>
        return @clusters.getChosenOption()

    showClusterError: (type, response)=>
        tryAgain = false
        if type == 'backendError'
            title = strings.CALC_BACKEND_ERROR_TITLE()
            msg = strings.CALC_BACKEND_ERROR_MSG({
                type: response["errorType"],
                msg: response["errorMessage"]
            })
        else if response.readyState == 0
            title = strings.CALC_CONNECTION_ERROR_TITLE()
            msg = strings.CALC_CONNECTION_ERROR_MSG()
            tryAgain = true
        else
            title = strings.CALC_STATUS_ERROR_TITLE()
            msg = strings.CALC_STATUS_ERROR_MSG({
                status: response.status,
                msg: response.responseText
            })
            if response.status in [500, 503]
                tryAgain = true

        @calcError = new CalcError({title: title, msg: msg, tryAgain: tryAgain})
        @resetResults()
        @listenTo(@calcError, 'tryAgain', @calculateTryAgain)
        @optionsRegion.show(@calcError)
        @removeLoader()


    listenToGroups: (trigger, func)=>
        for group in @reqsGroups
            @listenTo(group, trigger, func)

    updateShownTT: (shown)=>
        if shown?.isInner?()
            if shown.isRendered
                @trigger('adjustForTT', shown)
            else
                @trigger('adjustForTT')
            return
        if @shownTT?
            @shownTT.dismiss()
        @shownTT = shown
        @trigger('adjustForTT')


    openRegionInput: ()=>
        @regionGroup.openRegionInput()

    showLoader: ()=>
        loader = new common.Loader(type: 'optionsLoader')
        @optionsLoaderRegion.show(loader)

    removeLoader: ()=>
        @optionsLoaderRegion.reset()

    selectForExport: () =>
        @rowsForExport = []
        @withSelect = true
        this.render()

    optionAddedToExport: (option) =>
        @rowsForExport.push(option)
        if @rowsForExport.length == 3
            this.$el.find(".column_right .checkbox:not(:checked)").attr("disabled", true)

    optionRemovedFromExport: (option) =>
        @rowsForExport = @rowsForExport.filter((o) => o.model.cid != option.model.cid)
        this.$el.find(".column_right .checkbox:not(:checked)").removeAttr("disabled")

    cancelExport: () =>
        @withSelect = false
        this.render()

    export: () =>
        # Sort the results
        @rowsForExport.sort((o1, o2) => o1.model.cid.localeCompare(o2.model.cid))

        # Header titles
        headers = [
            sizerStrings.HP_CLUSTERS_TABLE_INSTANCE_HEADER().replaceAll('<br>', ' '),
            sizerStrings.HP_U_SPACES().replaceAll('<br>', ' '),
            sizerStrings.HP_POPULATED_SSDS().replaceAll('<br>', ' '),
            sizerStrings.CLUSTERS_TABLE_CAPACITY_HEADER().replaceAll('<br>', ' '),
            sizerStrings.CLUSTERS_TABLE_IOPS_HEADER().replaceAll('<br>', ' '),
            sizerStrings.CLUSTERS_TABLE_BW_HEADER().replaceAll('<br>', ' '),
            sizerStrings.REQ_NUMBER_OF_ENCLOSURES_LABEL().replaceAll('<br>', ' '),
            sizerStrings.EXPORT_HEADER_NUMBER_OF_SERVERS().replaceAll('<br>', ' '),
            sizerStrings.OPTION_DETAILS_STRIPING_LABEL().replaceAll('<br>', ' '),
            sizerStrings.OPTION_DETAILS_HOT_SPARES_LABEL().replaceAll('<br>', ' '),
            sizerStrings.OPTION_DETAILS_RAW_CAPACITY_LABEL().replaceAll('<br>', ' '),
            sizerStrings.OPTION_DETAILS_NETWORK_PORTS_LABEL().replaceAll('<br>', ' ')
        ]

        # RA Details Header titles
        raDetailsHeader = [
            "",
            sizerStrings.HP_CLUSTERS_TABLE_INSTANCE_HEADER().replaceAll('<br>', ' '),
            sizerStrings.RA_DETAILS_SSD_BAYS_LABEL(),
            sizerStrings.RA_DETAILS_SSD_CAPACITY_LABEL(),
            sizerStrings.RA_DETAILS_SSD_MODEL_LABEL(),
            sizerStrings.RA_DETAILS_CPUS_NUMBER_LABEL(),
            sizerStrings.RA_DETAILS_CORES_PER_CPU_LABEL(),
            sizerStrings.RA_DETAILS_CPU_MODEL_LABEL(),
            sizerStrings.RA_DETAILS_CPU_CLOCK_LABEL(),
            sizerStrings.RA_DETAILS_NETWORK_PORTS_LABEL(),
            sizerStrings.RA_DETAILS_NETWORK_PORT_SPEED_LABEL(),
            sizerStrings.RA_DETAILS_US_LABEL(),
            sizerStrings.RA_DETAILS_SERVERS_PER_ENCLOSURES_LABEL()
        ]

        # Building data for html template
        data = []
        raDetails = []
        currentVendor = ""
        raNames = []
        for option, index in @rowsForExport
            headerRow = headers.slice()
            headerRow.unshift(option.model.attributes.vendor)
            optionRow = ["Option " + (index + 1)]
            comment = option.input.getInputVal()
            if comment
                optionRow[0] += ': <b>' + comment + '</b>'
            attr = option.model.attributes
            valuesRow = ["", attr.instance_name, attr.u_spaces, attr.populated_ssds, attr.capacity, attr.iops, attr.bw, attr.enclosures, attr.servers, attr.striping, attr.hotspare, attr.total_servers_raw,
                attr.total_ports]
            if currentVendor != attr.vendor
                data.push([headerRow, optionRow, valuesRow])
            else
                data.push([optionRow, valuesRow])
            currentVendor = attr.vendor

            if attr.instance_name not in raNames
                raDetailsRow = ["", attr.instance_name, attr.ssd_bays, attr.ssd_capacity, attr.ssd_model, attr.cpus_number, attr.cores_per_cpu,
                    attr.cpu_model, attr.cpu_clock, attr.network_ports, attr.network_port_speed, attr.ra_us, attr.servers_per_enclosures]
                raDetails.push(raDetailsRow)
                raNames.push(attr.instance_name)

        # Creating PDF
        pdfContent = exportTemplate({data, raDetailsHeader, raDetails})
        opts = {
            filename: 'Export.pdf',
            # image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { y: 0,  scrollY: 0 },
            # html2canvas: { dpi: 192 },
            # jsPDF: { orientation: 'landscape', unit: 'px', format: [1684, 1190] }
            jsPDF: { orientation: 'landscape', format: [1264, 893] }
            # html2canvas: { useCORS: true }
        }
        html2pdf(pdfContent, opts)

        # Closing the export
        @withSelect = false
        this.render()

    showButtonClicked: () =>
        @showOnly3Options = !@showOnly3Options
        this.render()

    serializeData: =>
        return{
            withSelect: @withSelect
            showExport: window.IS_HP,
            showOnly3Options: @showOnly3Options,
            limitOptions: !window.IS_HP,
            resultsTitle: if window.IS_HP then sizerStrings.RESULTS_TITLE else strings.RESULTS_TITLE
        }
    # toggleDebugConsole: (e)=>
    #     if e.key == 'd' && window.IS_HP
    #         if @debugConsoleRegion.hasView()
    #             @debugConsoleRegion.reset()
    #         else
    #             @debugConsoleRegion.show(new DebugConsoleView(log: @log))

module.exports = CalculatorSection
