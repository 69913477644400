loginTmpl = require('./tmpl/login.hbs')

class LoginLayout extends Marionette.ItemView
    template: loginTmpl

    onRender: =>
        script = document.createElement('script')
        script.src = 'https://apis.google.com/js/platform.js'
        document.body.appendChild(script)

    serializeData: =>
        return {
            user: sessionStorage.getItem('user')
        }

module.exports = LoginLayout