// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <li class='helpItem formatRow showAsTable'>\n            <span class='itemColumn'></span>\n            <span class='itemColumn'>"
    + alias4(((helper = (helper = helpers.total || (depth0 != null ? depth0.total : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"total","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class='itemColumn'>"
    + alias4(((helper = (helper = helpers.hot || (depth0 != null ? depth0.hot : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hot","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class='itemColumn'>"
    + alias4(((helper = (helper = helpers.cold || (depth0 != null ? depth0.cold : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cold","hash":{},"data":data}) : helper)))
    + "</span>\n        </li>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.rows : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <li class='helpItem formatRow showAsTable'>\n                <span class='itemColumn'>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</span>\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.value : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    <span class='itemColumn'>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : {},(depth0 != null ? depth0.rows : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <li class='helpItem formatRow'>\n                <span class='itemColumn'>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</span>\n                <span class='itemColumn'>"
    + alias2(alias1((depth0 != null ? depth0.value : depth0), depth0))
    + "</span>\n            </li>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : {},(depths[1] != null ? depths[1].showAsTable : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div class=\"moreInfoWrapper\">\n    <div class=\"helpMoreInfo\">"
    + container.escapeExpression(((helper = (helper = helpers.moreInfoLabel || (depth0 != null ? depth0.moreInfoLabel : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"moreInfoLabel","hash":{},"data":data}) : helper)))
    + "</div>\n    <div class=\"moreInfoRegion\"></div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<p class='help_title'>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</p>\n<ul class='helpList'>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAsTable : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "</ul>\n<p class='help_title help_subtitle'>"
    + alias4(((helper = (helper = helpers.subTitle || (depth0 != null ? depth0.subTitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subTitle","hash":{},"data":data}) : helper)))
    + "</p>\n<ul class='helpList'>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.secondaryRows : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.moreInfoLabel : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});
