keys =
    backspace: {up: 8, down: 8, press: null}
    tab: {up: 9, down: 9, press: null}
    enter: {up: 13, down: 13, press: 13}
    shift: {up: 16, down: 16, press: null}
    escape: {up: 27, down: 27, press: null}
    space: {up: 32, down: 32, press: 32}
    arrow_left: {up: 37, down: 37, press: null}
    arrow_up: {up: 38, down: 38, press: null}
    arrow_right: {up: 39, down: 39, press: null}
    arrow_down: {up: 40, down: 40, press: null}
    delete: {up: 46, down: 46, press: null}
    zero: {up: 48, down: 48, press: 48}
    nine: {up: 57, down: 57, press: 57}
    numpad_zero: {up: 96, down: 96, press: 96}
    numpad_nine: {up: 105, down: 105, press: 105}
    dot: {up: 190, down: 190, press: 46}
    decimal_dot: {up: 110, down: 110, press: 46}

key_codes =
    up: {}
    down: {}
    press: {}

for key,key_dict of keys
    for action,code of key_dict
        key_codes[action][key] = code

module.exports = {
    up_key_codes: key_codes.up
    down_key_codes: key_codes.down
    press_key_codes: key_codes.press
}
