const consts = require('../framework/consts.ecmascript');
const strings = require('../framework/strings.ecmascript');
const sizerStrings = require('../framework/sizerStrings.ecmascript');
const utils = require('../framework/utils.coffee');

var RequirementsModel = Backbone.Model.extend({
    requirements: ['capacity', 'coldCapacity', 'capacityUnits', 'tiering', 'sort', 'features', 'deploymentType', 
        'SSDPercentage', 'performance', 'performanceUnits', 'rw', 'iops', 'bw', 'region', 'customerType', 'license', 'capacityType',
        'vendor', 'ra', 'numberOfEnclosures', 'SSDsPerEnclosure'],

    initialize: function (options) {
        this.requirements.forEach((req) => {
            this[req] = options[req] != undefined ? options[req] : consts.defaultReqs[req];
        });
        this.calculatorURL = options.calcURL;
    },

    getRequirementsURL: function () {
        var reqs = {};
        this.requirements.forEach((req) => {
            reqs[req] = this[req];
        });
        var reqsString = encodeURIComponent(JSON.stringify(reqs));
        return "?requirements=" + reqsString;
    },

    getRequirements: function () {
        var tiered, sorting, perfType, userType, capacity_gb;

        tiered = (this.tiering) ? "tiered" : "ssdonly";

        if (!this.performance && !window.IS_HP) {
            perfType = "none";
        } else if (this.performanceUnits == 'iops') {
            perfType = "iops";
        } else {
            perfType = "bw";
        }
        capacity_gb = this.capacityUnits === 'tb' ? this.capacity * 1000 : this.capacity;
        return {
            capacity_gb: capacity_gb,
            cold_capacity: this.capacityType ? this.coldCapacity : 0,
            tiering: tiered,
            sorting: this.sort,
            future_versions: this.features === "Future",
            deployment_type: this.deploymentType,
            customer_type: this.customerType,
            license: this.license,
            ssd_capacity_percent: this.SSDPercentage,
            performance: this.performance,
            performance_type: perfType,
            bw: this.bw,
            iops: this.iops,
            read_part: this.rw,
            region: this.region,
            hostname: window.location.hostname,
            vendor: this.vendor,
            ra: this.ra,
            numberOfEnclosures: this.numberOfEnclosures,
            SSDsPerEnclosure: this.SSDsPerEnclosure,
            sizerPartnerToken: localStorage.getItem('sizerPartnerToken'),
            jsonName: localStorage.getItem('jsonName')
        };
    },

    sendCalcRequest: function () {
        var payload = this.getRequirements();
        var currentTS = new Date();
        this.lastCalcTS = currentTS;
        utils.post(this.calculatorURL, payload)
            .done((data) => {
                if (this.lastCalcTS === currentTS) {
                    if (data["errorMessage"]) {
                        this.trigger('calcError', 'backendError', data);
                    } else {
                        if ("label_modifier" in data) {
                            localStorage.setItem('label_modifier', JSON.stringify(data.label_modifier))
                        }
                        this.showClusterOptions(data);
                    }
                }
            })
            .fail((response) => {
                if (this.lastCalcTS === currentTS) {
                    this.trigger('calcError', 'clientError', response);
                }
            });
        if (window.location.hostname !== "localhost" && window.IS_HP) {
            utils.post(consts.APIs.logger, {
                event_type: _.includes(['true', null], localStorage.getItem('firstCapacityTabActive')) ? 'configuration by requirement' : 'configuration by sizing',
                hostname: window.location.hostname,
                timezone_offset: new Date().getTimezoneOffset(),
                email: localStorage.getItem('email'),
                data: payload
            })
        }
    },

    showClusterOptions: function (data) {
        var clusterModels = [];
        if (window.IS_HP) {
            data.options.forEach((option) => {
                clusterModels.push(new ClusterOption(option));
            });
            var options = {
                capacityUnits: this.capacityUnits,
            };
            var clusterModelsCollection = new HPClusterOptionsCollection(clusterModels, options);
            var exactOption = new HPClusterOptionsCollection([new ClusterOption(data.exact_option)], options);
        } else {
            data.options.forEach((option) => {
                option.region = this.region;
                clusterModels.push(new ClusterOption(option));
            });
            var options = {
                capacityUnits: this.capacityUnits,
                region: data['request']['region'],
                tiering: data['request']['tiering'] === 'tiered',
                isPerfRequired: data['request']['performance'],
            };
            var clusterModelsCollection = new ClusterOptionsCollection(clusterModels, options);
        }
        this.trigger('newOptions', clusterModelsCollection, exactOption, data.error);
    }
});

var ClusterOption = Backbone.Model.extend({
    noDataString: '-',

    getBECount: function () {
        return this.get('cluster_size');
    },

    getBEType: function () {
        return this.get('instance_name');
    },

    getRegion: function () {
        return this.get('region');
    },

    getDescription: function () {
        return strings.CLUSTERS_TABLE_INSTANCE_CLICK({
            stripe_protection: this.get('protection_stripe_size'),
            stripe_data: this.get('data_stripe_size'),
            weka_cores: this.get('weka_cores')
        });
    },

    getFormattedValue: function (key, mainKey, conversion) {
        // format by main key
        if (!mainKey) {
            mainKey = key
        }
        var value = this.get(key);
        if (value === undefined) {
            return this.noDataString;
        }
        if (conversion) {
            value *= conversion;
        }
        var decoration = {
            cluster_size: 0,
            weka_total_cost: 2,
            capacity: 1,
            capacity_s3_and_ssd: 1,
            bw: 1,
            iops: window.IS_HP ? 1 : 0
        };
        if (window.IS_HP && !this.attributes.display_cost && key === "weka_total_cost") {
            return this.noDataString;
        }
        if (decoration.hasOwnProperty(mainKey)) {
            if (Array.isArray(value)) {
                return value.map(v => this.formatNum(v, decoration[mainKey]))
            }
            return this.formatNum(value, decoration[mainKey])
        } else {
            return value
        }
    },

    formatNum: function (number, digits) {
        if (number >= 1000) {
            number = String(Math.round(number));
            return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        } else {
            return number.toFixed(digits)
        }
    },

});

var ClusterOptionsCollection = Backbone.Collection.extend({
    model: ClusterOption,

    initialize: function (models, options) {
        this.region = options.region;
        this.units = options.capacityUnits.toUpperCase();
        this.tiering = options.tiering;
        this.isPerfRequired = options.isPerfRequired;
    },

    getColsDescription: function () {
        let capacityConversion = (this.units === 'TB') ? 1 / 1000 : 1
        let cols = [
            {
                key: 'instance_name',
                title: strings.CLUSTERS_TABLE_INSTANCE_HEADER(),
                isBold: true,
                mouse_click_getter: 'getDescription'
            }, {
                key: 'cluster_size',
                title: strings.CLUSTERS_TABLE_SIZE_HEADER()
            }
        ];
        if (this.tiering) {
            cols.push({
                key: 'capacity_s3_and_ssd',
                title: strings.CLUSTERS_TABLE_CAPACITY_HEADER({units: this.units}),
                conversion: capacityConversion
            });
        }
        cols.push({
            key: 'capacity',
            title: strings.CLUSTERS_TABLE_SSD_CAPACITY_HEADER({units: this.units}),
            conversion: capacityConversion
        });
        cols.push({key: 'iops', title: strings.CLUSTERS_TABLE_IOPS_HEADER()});
        cols.push({key: 'bw', title: strings.CLUSTERS_TABLE_BW_HEADER()})
        // Hiding the price column
        // cols.push({
        //    key: 'weka_total_cost',
        //    title: strings.CLUSTERS_TABLE_COST_HEADER(),
        //    isBold: true,
        //    question_mark_info: {
        //        title: strings.COST_INFO_TITLE(),
        //        rows: [
        //            {key: 'weka_ssd_cost', title: strings.WEKA_COST_LABEL()},
        //            {key: 'weka_obs_cost', title: strings.WEKA_S3_COST_LABEL()}
        //        ],
        //        more_info: {key: 'pricing_info_message', label: strings.MORE_INFO_LABEL()}
        //    }
        // });
        return cols;
    }
});

var HPClusterOptionsCollection = Backbone.Collection.extend({
    model: ClusterOption,

    initialize: function (models, options) {
        this.units = options.capacityUnits.toUpperCase();
        this.models = models;
    },

    getColsDescription: function () {
        let capacityConversion = (this.units === 'TB') ? 1 / 1000 : 1
        let cols = [
            {
                key: 'vendor',
                title: sizerStrings.HP_CLUSTERS_VENDOR_HEADER(),
                isBold: true,
                // mouse_click_getter: 'getDescription'
            },
            {
                key: 'instance_name',
                title: sizerStrings.HP_CLUSTERS_TABLE_INSTANCE_HEADER(),
                isBold: true,
                question_mark_info: {
                    title: sizerStrings.OPTION_INFO_TITLE(),
                    rows: [
                        {key: 'ssd_bays', title: sizerStrings.RA_DETAILS_SSD_BAYS_LABEL()},
                        {key: 'ssd_capacity', title: sizerStrings.RA_DETAILS_SSD_CAPACITY_LABEL()},
                        {key: 'ssd_model', title: sizerStrings.RA_DETAILS_SSD_MODEL_LABEL()},
                        {key: 'cpus_number', title: sizerStrings.RA_DETAILS_CPUS_NUMBER_LABEL()},
                        {key: 'cores_per_cpu', title: sizerStrings.RA_DETAILS_CORES_PER_CPU_LABEL()},
                        {key: 'cpu_model', title: sizerStrings.RA_DETAILS_CPU_MODEL_LABEL()},
                        {key: 'cpu_clock', title: sizerStrings.RA_DETAILS_CPU_CLOCK_LABEL()},
                        {key: 'network_ports', title: sizerStrings.RA_DETAILS_NETWORK_PORTS_LABEL()},
                        {key: 'network_port_speed', title: sizerStrings.RA_DETAILS_NETWORK_PORT_SPEED_LABEL()},
                        {key: 'ra_us', title: sizerStrings.RA_DETAILS_US_LABEL()},
                        {key: 'servers_per_enclosures', title: sizerStrings.RA_DETAILS_SERVERS_PER_ENCLOSURES_LABEL()},
                    ]
                }
            }, {
                key: 'u_spaces',
                title: sizerStrings.HP_U_SPACES(),
                question_mark_info: {
                    title: sizerStrings.OPTION_INFO_TITLE(),
                    rows: [
                        {key: 'enclosures', title: sizerStrings.OPTION_DETAILS_ENCLOSURES_LABEL()},
                        {key: 'u_spaces', title: sizerStrings.OPTION_DETAILS_U_SPACES_LABEL()},
                        {key: 'servers', title: sizerStrings.OPTION_DETAILS_SERVERS_LABEL()},
                        {key: 'populated_ssds', title: sizerStrings.OPTION_DETAILS_SSDS_LABEL()},
                        {key: 'striping', title: sizerStrings.OPTION_DETAILS_STRIPING_LABEL()},
                        {key: 'hotspare', title: sizerStrings.OPTION_DETAILS_HOT_SPARES_LABEL()},
                        {key: 'total_servers_raw', title: sizerStrings.OPTION_DETAILS_RAW_CAPACITY_LABEL()},
                        {key: 'total_ports', title: sizerStrings.OPTION_DETAILS_NETWORK_PORTS_LABEL()},
                    ]
                }
            }, {
                key: 'populated_ssds',
                title: sizerStrings.HP_POPULATED_SSDS()
            }, {
                key: 'capacity',
                title: sizerStrings.CLUSTERS_TABLE_CAPACITY_HEADER({units: this.units}),
                // conversion: capacityConversion
            }, {
                key: 'iops',
                title: sizerStrings.CLUSTERS_TABLE_IOPS_HEADER(),
                // conversion: capacityConversion
            }, {
                key: 'bw',
                title: sizerStrings.CLUSTERS_TABLE_BW_HEADER(),
                // conversion: capacityConversion
            }, {
                key: 'weka_total_cost',
                title: sizerStrings.CLUSTERS_TABLE_COST_HEADER(),
                isBold: true,
                question_mark_info: {
                    title: sizerStrings.COST_INFO_TITLE(),
                    rows: [
                        {key: 'hardware_cost_help', title: sizerStrings.WEKA_TOTAL_HARDWARE_COST_LABEL()},
                        {key: 'weka_transfer_help', title: sizerStrings.WEKA_TRANSFER_COST_LABEL()},
                        {key: 'reseller_license_markup_help', title: sizerStrings.WEKA_RESELLER_LICENSE_MARKUP_LABEL()},
                    ],
                    subTitle: sizerStrings.COST_INFO_SUBTITLE(),
                    secondaryRows: [
                        {key: 'cost/tb', title: sizerStrings.COST_PER_TB_LABEL()},
                        {key: 'cost/iops', title: sizerStrings.COST_PER_IOPS_LABEL()},
                        {key: 'cost/bw', title: sizerStrings.COST_PER_BW_LABEL()},
                    ],
                    more_info: {key: 'pricing_info_message', label: sizerStrings.MORE_INFO_LABEL()}
                }
            }
        ];
        return cols;
    },

    // It was used for debug purposes. Not in use anymore
    // getLog: function () {
    //    let output = ''
    //    this.models.forEach((obj) => {
    //        let log = JSON.parse(obj.attributes.log)
    //        output += log.ra.name + ':\n'
    //        for (const [key, value] of Object.entries(log)) {
    //            output += key === 'ra' ? `${key}: ${JSON.stringify(value)}\n` : `${key}: ${value}\n`
    //        }
    //        output += '\n' 
    //    })
    //    return output
    //}

});

var Param = Backbone.Model.extend({
    getBaseWidth: function () {
        return this.get('baseWidth');
    },
    isHeader: function () {
        return this.get('isHeader');
    },
    getCluster: function () {
        return this.get('cluster');
    },
    getInfo: function () {
        return this.get('question_mark_info');
    },
    getMouseClickGetter: function () {
        return this.get('mouse_click_getter');
    },
    getKey: function () {
        return this.get('key');
    },
    getValue: function () {
        return this.get('value');
    },
    isBold: function () {
        return this.get('isBold');
    }
});

var ParamsCollection = Backbone.Collection.extend({
    model: Param
});

var ClusterSettings = Backbone.Model.extend({
    requirements: ['version', 'determinedVersion', 'withClients', 'clientsType', 'clientsCount',
        'customBE', 'BEType', 'BECount', 'ami', 'customAmi'],

    initialize: function (options) {
        if (options == undefined) {
            options = {};
        }
        _.each(this.requirements, (req) => {
            this[req] = options[req] != undefined ? options[req] : consts.clusterDefaults[req];
        });
    },

    getClientsConfig: function () {
        var clientsCount;
        clientsCount = (this.withClients) ? this.clientsCount : 0;
        var settings = {
            role: 'client',
            instance_type: this.clientsType,
            count: Number(clientsCount)
        };
        if (this.ami == 'custom' && this.customAmi != '') {
            settings.ami_id = this.customAmi;
        }
        return settings;
    },

    getBEConfig: function () {
        if (this.customBE) {
            return {
                role: "backend",
                instance_type: this.BEType,
                count: this.BECount
            };
        }
    },

    resetVersions: function () {
        this.determinedVersion = true;
        this.version = undefined;
        this.selectedTrunk = undefined;
    }
});

var VersionsModel = Backbone.Model.extend({
    fetchTrunks: function (all = true) {
        let url = consts.releasesBucketURL + consts.releasesAPIPaths.trunks;
        if (all) {
            return this.fetchPages(url, 'trunk');
        }
        return this.fetchPage(url, 'trunk');
    },

    fetchVersions: function (trunkId, all = true) {
        let url = consts.releasesBucketURL + consts.releasesAPIPaths.versions + `?trunk=${trunkId}`;
        if (all) {
            return this.fetchPages(url, trunkId);
        }
        return this.fetchPage(url, trunkId);
    },

    saveObjects: function (data, counterKey) {
        if (counterKey == 'trunk') {
            _.each(data.objects, (trunk) => {
                if (!(trunk.id in this.versionsByTrunk)) {
                    if (_.isEmpty(this.versionsByTrunk)) {
                        this.publicTrunkId = trunk.id;
                    }
                    this.versionsByTrunk[trunk.id] = [];
                    this.trunks.push(trunk);
                }
            });
        } else {
            this.versionsByTrunk[counterKey] = this.versionsByTrunk[counterKey].concat(data.objects);
        }
        if (data.num_pages == data.page) {
            this.fetchedAll.push(counterKey);
        }
    },

    fetchPage: function (url, counterKey) {
        let countersObject = counterKey == 'trunk' ? this.counters : this.counters.version;
        if (!(counterKey in countersObject)) {
            countersObject[counterKey] = 0;
        }
        let pageNum = countersObject[counterKey] + 1;
        let data = {page: pageNum};
        let headers = {'Authorization': localStorage.getItem('accessTokenType') + ' ' + localStorage.getItem('accessToken')};
        let req_func = () => {
            return utils.get(url, data, headers);
        };
        let deferred = utils.request_with_retry(req_func)
            .done((data) => {
                countersObject[counterKey] = data.page;
                this.saveObjects(data, counterKey);
            });
        return deferred;
    },

    fetchPages: function (url, counterKey) {
        let deferred = $.Deferred();
        let next = (data) => {
            if (data.num_pages > data.page) {
                fetch();
            } else {
                deferred.resolve();
            }
        };
        let fetch = () => {
            let fetchDeferred = this.fetchPage(url, counterKey);
            fetchDeferred.done(next);
            fetchDeferred.fail((err) => {
                deferred.reject(err);
            });
        };
        fetch();
        return deferred;
    },

    FetchAllTrunks: function () {
        let deferred = $.Deferred();
        if (this.fetchedAll.indexOf('trunk') > -1) {
            deferred.resolve();
        } else {
            deferred = this.fetchTrunks();
        }
        return deferred;
    },

    FetchAllVersions: function (trunkId = this.publicTrunkId) {
        let deferred = $.Deferred();
        if (this.fetchedAll.indexOf(trunkId) > -1) {
            deferred.resolve();
        } else {
            deferred = this.fetchVersions(trunkId);
        }
        return deferred;
    },

    setDefaultVersion: function () {
        this.defaultVersion = _.find(this.versionsByTrunk[this.publicTrunkId], function (version) {
            return version.public
        });
        if (!this.defaultVersion) {
            this.defaultVersion = this.versionsByTrunk[this.publicTrunkId][0];
        }
    },

    _init: function () {
        if (this.defaultVersionDeferred === undefined || this.defaultVersionDeferred.state() === 'rejected') {
            this.defaultVersionDeferred = $.Deferred();
            let fetchPublicVersions = () => {
                let versionsDeferred = this.fetchVersions(this.publicTrunkId, false);
                versionsDeferred.done(() => {
                    this.setDefaultVersion();
                    this.defaultVersionDeferred.resolve();
                });
                versionsDeferred.fail(this.defaultVersionDeferred.reject);
            };
            if (this.publicTrunkId != undefined) {
                fetchPublicVersions()
            } else {
                let trunksDeferred = this.fetchTrunks(false);
                trunksDeferred.done(fetchPublicVersions);
                trunksDeferred.fail(this.defaultVersionDeferred.reject);
            }
        }
        return this.defaultVersionDeferred;
    },

    initialize: function (options) {
        this.versionsByTrunk = {};
        this.trunks = [];
        this.counters = {trunk: 0, version: {}};
        this.fetchedAll = [];
        this._init();
    }
});


module.exports = {
    RequirementsModel: RequirementsModel,
    ClusterOptionsCollection: ClusterOptionsCollection,
    ClusterOption: ClusterOption,
    ParamsCollection: ParamsCollection,
    Param: Param,
    ClusterSettings: ClusterSettings,
    VersionsModel: VersionsModel
};
