// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.email || (depth0 != null ? depth0.email : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : {},{"name":"email","hash":{},"data":data}) : helper)));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : {}, alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"partner-login\">\n  <h1 class=\"header_title\">Plan your <span class=\"header_purple\">Weka</span> with "
    + alias4(((helper = (helper = helpers.partnername || (depth0 != null ? depth0.partnername : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"partnername","hash":{},"data":data}) : helper)))
    + ".</h1>\n  <h2 class=\"header_subtitle\">THE FILE SYSTEM FOR THOSE WHO SOLVE BIG PROBLEMS.</h2>\n  <p class=\"header_text\">\n      This Sizer is designed as a conversation starter with high-level performance numbers for the different reference architecture. \n  </p>\n  <p class=\"header_text\">    \n      If you need help you may want to visit&nbsp;<a id=\"docs_link\" href=\""
    + alias4(((helper = (helper = helpers.docsURL || (depth0 != null ? depth0.docsURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"docsURL","hash":{},"data":data}) : helper)))
    + "\">Weka Documentation.</a>\n  </p>\n\n  <div class=\"login\">\n    <div class=\"sso\">\n      <h2>I am a Weka Employee</h2>\n      <div class=\"g-signin2\" data-onsuccess=\"onSignIn\" data-theme=\"dark\"></div>\n    </div>\n    <div class=\"partner\">\n      <h2>I am a "
    + alias4(((helper = (helper = helpers.partnername || (depth0 != null ? depth0.partnername : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"partnername","hash":{},"data":data}) : helper)))
    + " Employee</h2>\n      <span> My email is: </span>\n      <input value=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.email : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"/>\n      <span>@"
    + alias4(((helper = (helper = helpers.emaildomain || (depth0 != null ? depth0.emaildomain : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"emaildomain","hash":{},"data":data}) : helper)))
    + "</span>\n      <button>Email me an access link</button>\n    </div>\n  </div>  \n</div>\n";
},"useData":true});
