Calculator = require('./main/main.coffee')
Login = require('./main/login.coffee')
Page404 = require('./main/page404.coffee')
PartnerLogin = require('./sizer/auth/PartnerLogin/PartnerLogin.coffee')
ExpiredToken = require('./sizer/auth/ExpiredToken/ExpiredToken.coffee')

consts = require('./framework/consts.ecmascript')
utils = require('./framework/utils.coffee');


class CalculatorApp extends Marionette.Application
    onStart: (options)=>
        @addRegions({
            main: '#app'
        })

        urlParams = new URLSearchParams(window.location.search)
        jsonName = urlParams.get('json_name')
        if window.IS_HP && jsonName
            localStorage.setItem('jsonName', jsonName)
        else
            localStorage.removeItem('jsonName')

        if window.IS_HP && !localStorage.getItem('accessToken')
            params = {hostname: window.location.hostname}
            if jsonName
                params["jsonName"] = jsonName
            utils.get(consts.APIs.allowedSites, params)
                .done (data)=>
                    hostname = window.location.hostname
                    if Object.keys(data).length == 0 && hostname != "sizer-staging.dev.weka.io" && hostname != "sizer.weka.io" && hostname != "localhost"
                        @main.show(new Page404())
                    else
                        if data.hiddenRequirements
                            localStorage.setItem('hiddenRequirements', data.hiddenRequirements)
                        if data.partnername
                            access_code = urlParams.get('access_code')
                            token = localStorage.getItem('sizerPartnerToken')
                            if token
                                utils.post(consts.APIs.token, {token, hostname: params["hostname"]})
                                    .done (tokenData)=>
                                        if tokenData.expired
                                            localStorage.removeItem('sizerPartnerToken')
                                            localStorage.removeItem('user')
                                            localStorage.removeItem('email')
                                            @layout = new ExpiredToken(email: tokenData.email, partnername: data.partnername)
                                        else if tokenData.error
                                            localStorage.removeItem('sizerPartnerToken')
                                            localStorage.removeItem('user')
                                            localStorage.removeItem('email')
                                            @layout = new PartnerLogin(emaildomain: data.emaildomain, partnername: data.partnername)
                                        else
                                            @layout = new Calculator(options)
                                        @main.show(@layout)
                            else if access_code
                                params["access_code"] = access_code
                                utils.post(consts.APIs.token, params)
                                    .done (tokenData)=>
                                        if tokenData.error
                                            @main.show(new PartnerLogin(emaildomain: data.emaildomain, partnername: data.partnername, email: tokenData.email))
                                            showError = () ->
                                                alert(tokenData.error)
                                            setTimeout(showError, 1000)
                                        else
                                            localStorage.removeItem('accessToken')
                                            localStorage.setItem('sizerPartnerToken', tokenData.token)
                                            localStorage.setItem('user', tokenData.email)
                                            localStorage.setItem('email', tokenData.email)
                                            @main.show(new Calculator(options))
                            else
                                @main.show(new PartnerLogin(emaildomain: data.emaildomain, partnername: data.partnername))
                        else
                            @main.show(new Login())
        else
            if window.IS_HP
                loginTime = localStorage.getItem('loginTime')
                if !loginTime || (loginTime && ((new Date().getTime() - loginTime) / 1000) > 3600)
                    options.shouldLogout = true
            @main.show(new Calculator(options))
    triggerIntercom: =>
        @trigger('intercom:init')

window.startApp = (options) ->
    app = new CalculatorApp()
    window.app = app
    window.onresize = _.debounce( ()->
        app.trigger('window:resize')
    , 200)
    app.start(options)

$(document).ready(()->
    window.startApp({stage: APP_STAGE})
    )
