jwt = require('jsonwebtoken');
sizerStrings = require('../../framework/sizerStrings.ecmascript')
requirements = require('../../requirements/requirements.coffee')
RequirementsGroup = requirements.RequirementsGroup


class CapacityGroup extends RequirementsGroup

  ui:
      tab: '.tab'
  events:
      'click @ui.tab': 'toggleTab'

  reqsMap:
    'totalCapacity':
      label: sizerStrings.REQ_NET_CAPACITY_LABEL()
      input:
        type: 'number'
        unit: 'TB'
        float: true
        min: 1
    'capacityType':
      input:
          type: 'switch'
          leftLabel: sizerStrings.REQ_CAPACITY_TYPE_HOT_LABEL()
          rightLabel: sizerStrings.REQ_CAPACITY_TYPE_HOT_AND_COLD_LABEL()
    'coldCapacity':
      label: sizerStrings.REQ_COLD_CAPACITY_LABEL()
      input:
        type: 'number'
        unit: 'TB'
        float: true
        min: 0
    'vendor':
      label: sizerStrings.REQ_VENDOR_LABEL()
      input:
        type: 'dropdown'
        options: ['selected': false]
    'ra':
      label: sizerStrings.REQ_RA_LABEL()
      input:
        type: 'dropdown'
        options: ['selected': false]
    'numberOfEnclosures':
      label: sizerStrings.REQ_NUMBER_OF_ENCLOSURES_LABEL()
      input:
        type: 'number'
        min: 1
    'SSDsPerEnclosure':
      label: sizerStrings.REQ_SSDS_PER_ENCLOSURE_LABEL()
      input:
        type: 'dropdown'
        options: ['selected': false]

  onRender: ()=>
      super()

      @savedRa = JSON.parse(localStorage.getItem('ra'))
      if @savedRa
        @vendor.setVal(@savedRa.vendor)
        if !@numberOfEnclosures.getVal()
          @numberOfEnclosures.setVal(@savedRa.min_servers_in_cluster)

      @coldCapacity.hide(true)
      if @capacityType.getVal()
          @coldCapacity.hide(false)
      if _.includes(['true', null], localStorage.getItem('firstCapacityTabActive'))
        @totalCapacity.hide(false)
        @vendor.hide(true)
        @ra.hide(true)
        @numberOfEnclosures.hide(true)
        @SSDsPerEnclosure.hide(true)
      else
        @totalCapacity.hide(true)
        @vendor.hide(false)
        @ra.hide(false)
        @numberOfEnclosures.hide(false)
        @SSDsPerEnclosure.hide(false)
      @listenTo(@capacityType, 'change', @toggleCapacityType)
      @listenTo(@vendor, 'change', @toggleVendor)
      @listenTo(@ra, 'change', @toggleRa)
      
  setInputValues: () =>
      if @vendors
        @vendors = Array.from(@vendors)
        @savedRa = JSON.parse(localStorage.getItem('ra'))
        @savedVendor = if @savedRa then @savedRa.vendor else @vendors[0]
        
        @vendors = @vendors.map((v) => if v == @savedVendor then {text: v, value: v, selected: true} else {text: v, value: v})
        @reqsMap.vendor.input.options = @vendors
        @reqsMap.vendor.input.value = @savedVendor

        @ras = Array.from(@allRas).filter((ra) => ra.vendor == @savedVendor)
        if !@savedRa
          @savedRa = @ras[0]
          localStorage.setItem('ra', JSON.stringify(@savedRa))
          location.reload()
        @reqsMap.ra.input.options = Array.from(new Set(@ras.map((v) => v.instance_name))).map((v) => if v == @savedRa.instance_name then {text: v, value: v, selected: true} else {text: v, value: v})
        @reqsMap.SSDsPerEnclosure.input.options = @savedRa.ssds_options.map((v) => if v == @savedRa.ssds_options[0] then {text: v, value: v, selected: true} else {text: v, value: v})

  toggleVendor: () =>
      localStorage.setItem('ra', JSON.stringify(Array.from(@allRas).filter((ra) => ra.vendor == @vendor.getVal())[0]))
      location.reload()
  
  toggleRa: () =>
      localStorage.setItem('ra', JSON.stringify(Array.from(@allRas).filter((ra) => ra.instance_name == @ra.getVal())[0]))
      this.render()

  toggleTab: (e) =>
      requirementsTabOpen = event.target.firstChild.nodeValue == 'By Requirements'
      localStorage.setItem('firstCapacityTabActive', requirementsTabOpen)
      if requirementsTabOpen
        this.$el.parent().siblings('.performanceGroup').find(".region_performanceUnits").css('display', 'block')
        this.$el.parent().siblings('.performanceGroup').find(".region_iops").css('display', 'block')
        this.$el.parent().siblings('.performanceGroup').find(".region_bw").css('display', 'block')
      else
        this.$el.parent().siblings('.performanceGroup').find(".region_performanceUnits").css('display', 'none')
        this.$el.parent().siblings('.performanceGroup').find(".region_iops").css('display', 'none')
        this.$el.parent().siblings('.performanceGroup').find(".region_bw").css('display', 'none')
      @trigger('CapacityTabSwitched')
      this.render()
      @triggerCalc()

  toggleCapacityType: () =>
      if @capacityType.getVal()
          @coldCapacity.hide(false)
      else
          @coldCapacity.hide(true)

  getGroupHelp: ()=>
      return ''

  initialize: (options)=>
      super(options)
      @name = sizerStrings.REQ_GROUP_CAPACITY_LABEL()
      @vendors = options.vendors
      @allRas = options.ras
      @setInputValues()

class CustomerAndDealGroup extends RequirementsGroup
  reqsMap:
    'license':
      label: sizerStrings.LICENSE_PERIOS_LABEL()
      input:
        type: 'radio'
        options: [
          {
            name: sizerStrings.PERIOD_1Y_LABEL()
            value: '1Y'
          },
          {
            name: sizerStrings.PERIOD_3Y_LABEL()
            value: '3Y'
          },
          {
            name: sizerStrings.PERIOD_5Y_LABEL()
            value: '5Y'
          }
        ]
    'customerType':
      label: sizerStrings.CUSTOMER_TYPE_LABEL()
      input:
        type: 'radio'
        options: [
          {
            name: sizerStrings.COMMERCIAL_LABEL()
            value: 'commercial'
          },
          {
            name: sizerStrings.GOV_EDU_LABEL()
            value: 'GovEdu'
          }
        ]
    'features':
      helpText: sizerStrings.FUTURE_VERSIONS_HELP_TEXT()
      label: sizerStrings.FUTURE_VERSIONS_LABEL()
      input:
        type: 'radio'
        options: [
          {
            name: sizerStrings.OFF_LABEL()
            value: 'GA'
          },
          {
            name: sizerStrings.ON_LABEL()
            value: 'Future'
          }
        ]
    'deploymentType':
      helpText: sizerStrings.DEPLOYMENT_TYPE_HELP_LABEL()
      label: sizerStrings.DEPLOYMENT_TYPE_LABEL()
      input:
        type: 'radio'
        options: [
          {
            name: sizerStrings.PRODUCTION_LABEL()
            value: 'production'
          },
          {
            name: sizerStrings.POC_LABEL()
            value: 'poc'
          }
        ]

  getGroupHelp: ()=>
      return ''

  initialize: (options)=>  
      # Hiding requirements options for partner
      token = localStorage.getItem('sizerPartnerToken')
      hiddenRequirements = localStorage.getItem('hiddenRequirements')
      if token
        decoded = jwt.decode(token, 'sizer_secret')
        hidden = _.keys(_.pick(decoded.input_modifier, (v, k) => !v.visibility))
        @reqsMap = _.pick(@reqsMap, (v, k) => !_.includes(hidden, k))
      if hiddenRequirements
        @reqsMap = _.pick(@reqsMap, (v, k) => !_.includes(hiddenRequirements.split(","), k))

      super(options)
      @name = sizerStrings.REQ_CUSTOMER_AND_DEAL_GROUP_LABEL()

module.exports = {
    CapacityGroup: CapacityGroup,
    CustomerAndDealGroup: CustomerAndDealGroup
}