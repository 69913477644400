layoutTmpl = require('./tmpl/layout.hbs')
footerTmpl = require('./tmpl/footer.hbs')

strings = require('../framework/strings.ecmascript')
consts = require('../framework/consts.ecmascript')
utils = require('../framework/utils.coffee')
popupFactory = require('../widgets/dialogs/popups.coffee')
models = require('./models.ecmascript')

CalculatorHeaderView = require('./layoutHeader.coffee')
ContactUsForm = require('./contact_us.coffee')
CalculatorSection = require('./calculator.coffee')
ClusterConfView = require('../cluster_config/cluster_config.coffee')


class UserModel extends Backbone.Model

    authUser: ()=>
        headers = {'Authorization': localStorage.getItem('accessTokenType') + ' ' + localStorage.getItem('accessToken')}
        req_func = () => return utils.get(consts.releasesBucketURL + consts.releasesAPIPaths.auth, null, headers)
        return utils.request_with_retry(req_func)

    login: (code) =>
        if @loggingDeferred? and @loggingDeferred.state() != 'rejected'
            return @loggingDeferred
        @loggingDeferred = $.Deferred()
        @loggingDeferred.done (data)=>
            @setUserData(data)
        @loggingDeferred.fail (response)=>
            if code?
                @trigger('login_failure', response)
        @trigger('new_login', @loggingDeferred)
        authWithToken = () =>
            deferred = @authUser()
            deferred.done (data)=>
                @loggingDeferred.resolve(data)
            deferred.fail (res)=>
                @loggingDeferred.reject(res)
        if not code?
            if localStorage.getItem('accessToken')? and localStorage.getItem('accessTokenType')?
                authWithToken()
            else
                @loggingDeferred.reject()
        else
            req_func = () => return utils.get(consts.releasesBucketURL + consts.releasesAPIPaths.getToken + "?code=#{code}")
            utils.request_with_retry(req_func)
                .done (data) =>
                    localStorage.setItem('accessToken', data.access_token)
                    localStorage.setItem('accessTokenType', data.token_type)
                    authWithToken()
                .fail (response) =>
                    @loggingDeferred.reject(response)

        return @loggingDeferred

    logout: ()=>
        localStorage.removeItem('user')
        localStorage.removeItem('email')
        localStorage.removeItem('accessToken')
        localStorage.removeItem('accessTokenType')
        localStorage.removeItem('ra')
        localStorage.removeItem('hiddenRequirements')
        localStorage.removeItem('label_modifier')
        localStorage.removeItem('firstCapacityTabActive')
        localStorage.removeItem('selectedVendors')
        @setUserData(undefined)
        if window.IS_HP
            if localStorage.getItem('sizerPartnerToken')
                localStorage.removeItem('sizerPartnerToken')
                window.location = window.location.pathname
            else
                try
                    window.signOut()
                catch
                    console.log("no gapi")
        @trigger('logout')

    getVersionsDeferred: () =>
        if @versionsModel?
            return @versionsModel._init()
        deferred = $.Deferred()
        deferred.reject()
        return deferred

    isDefaultVersionFetched: =>
        return @versionsModel?.defaultVersion?

    setUserData: (data)=>
        if data?
            @set('name', data.display_name)
            @set('emails', data.emails)
            @set('userId', data.id)
            @versionsModel = new models.VersionsModel()
        else
            @set('name', undefined)
            @set('emails', undefined)
            @set('userId', undefined)
            @versionsModel = undefined
            @loggingDeferred = undefined

    getName: =>
        return @get('name')

    isLoggedIn: =>
        return localStorage.getItem('user') || @get('userId')?

    isLoggingIn: =>
        return @loggingDeferred?.state() == 'pending'

    isWekaUser: ()=>
        whitelisted_domains = [
            'weka.io'
        ]
        for email in @get('emails')
            domain = email.split('@')[1]
            if  domain in whitelisted_domains
                 return true
        return false

    triggerShowLogin: =>
        @trigger('show_login')

    initialize: (options)=>
        @login()


class CalculatorFooterView extends Marionette.LayoutView
    template: footerTmpl
    className: 'CalculatorFooterView'
    ui:
        'socialWrapper': '.footer_social-wrapp'

    socialAdjust: ()=>
        intercomOffset = $(".intercom-launcher-frame").offset()
        if not intercomOffset?
            return
        @clearIntercomInterval()
        diff = intercomOffset.left - @ui.socialWrapper.offset().left - 150
        marginPx = @ui.socialWrapper.css("margin-right")
        margin = Number(marginPx.slice(0, -2))
        if diff < 0 or margin > diff
            @ui.socialWrapper.css("margin-right", margin - diff + "px")
        else
            @ui.socialWrapper.css("margin-right", "0px")

    intercomInit: =>
        @intercomInitInterval = setInterval(@socialAdjust, 500)
        setTimeout(@clearIntercomInterval, 5000)

    clearIntercomInterval: =>
        clearInterval(@intercomInitInterval)

    onRender: ()=>
        @listenTo(app, 'window:resize', @socialAdjust)
        @listenTo(app, 'intercom:init', @intercomInit)

    serializeData: ()=>
        return {
            year: (new Date()).getFullYear()
        }


class CalculatorLayout extends Marionette.LayoutView
    template: layoutTmpl
    className: 'Calculator'
    regions:
        headerRegion: '.header'
        mainRegion: '.mainRegion'
        footerRegion: '.footer'
        modalsRegion: '.modalsRegion'
    events:
        'click': 'dismissTooltips'

    onRender: =>
        if @shouldLogout
            @logout()
            @userModel.logout()
        calculatorOpts = Object.assign({calcURL: @calcURL}, @reqsData)
        @calculator = new CalculatorSection(calculatorOpts)
        @headerContent = new CalculatorHeaderView(reqsModel: @calculator.reqsModel, userModel: @userModel)
        @footerContent = new CalculatorFooterView()
        @listenTo(@headerContent, 'contact_us', @showContactUsForm)
        @listenTo(@headerContent, 'go_to_docs', @goToDocs)
        @listenTo(@userModel, 'show_login', @login)
        @listenTo(@userModel, 'logout', @logout)
        @listenTo(@userModel, 'login_failure', @showLoginFailure)
        @listenTo(@calculator, 'adjustForTT', @adjustHeightForTT)
        @listenTo(@calculator, 'deploy', @deployCluster)
        @headerRegion.show(@headerContent)
        @mainRegion.show(@calculator)
        @footerRegion.show(@footerContent)

    getChosenClusterOption: =>
        return @calculator.getChosenOption()

    getChosenCluster: =>
        return @getChosenClusterOption()?.model

    getConfigNote: (callback)=>
        if @calculator.reqsModel.tiering
            return new popupFactory.createNote(
                title: strings.S3_NOTE_TITLE(),
                text: strings.S3_NOTE_MSG(),
                button:
                    text: strings.CONTINUE_DEPLOYMENT()
                    type: 'continue'
                    action: callback
                class: 's3Note'
            )
        return undefined

    showLogin: ()=>
        @loginNote = new popupFactory.createNote(
            title: strings.LOGIN_NOTE_TITLE(),
            text: strings.LOGIN_NOTE_MSG(),
            button:
                text: strings.LOGIN_BUTTON_LABEL()
                type: 'continue'
                id: 'Login Button'
                action: @userModel.triggerShowLogin
            class: 'loginNote'
        )
        @listenTo(@loginNote, 'dismiss', @dismissModal)
        @modalsRegion.show(@loginNote)

        continueDeployment = ()=> setTimeout(()=>
            @getVersionAndDeploy()
        )
        @loginNote.listenToOnce(@userModel, 'change:userId', continueDeployment)
        @loginNote.listenTo(@userModel, 'new_login', @loginNote.setButtonDeferred)

    showVersionsFailureNote: ()=>
        @versionFailureNote = popupFactory.createNote(
            button:
                text: strings.TRY_AGAIN()
                type: 'retry'
                action: @getVersionAndDeploy
            title: strings.VERSION_FETCH_FAILURE_TITLE()
            text: strings.VERSION_FETCH_FAILURE_MSG()
            class: 'versionsFailure'
        )
        @showDialog(@versionFailureNote)

    showClusterConfig: ()=>
        if not @clusterSettingsModel?
            @clusterSettingsModel = new models.ClusterSettings()
        clusterConfView = new ClusterConfView({
            cluster: @getChosenCluster(),
            versionsModel: @userModel.versionsModel,
            model: @clusterSettingsModel,
            wekaUser: @userModel.isWekaUser()
        })
        @clusterConfiguration = popupFactory.createCustom(
            title: strings.CLUSTER_CONFIG_TITLE()
            class: 'ClusterConfig'
            body: clusterConfView
        )
        @listenTo(@clusterConfiguration, 'dismiss', @dismissModal)
        @listenTo(@clusterConfiguration, 'change_region', @openRegionInput)
        @modalsRegion.show(@clusterConfiguration)

    deployCluster: ()=>
        configDeferred = $.Deferred()
        @configNote = @getConfigNote(configDeferred.resolve)
        if @configNote?
            @listenTo(@configNote, 'dismiss', @dismissModal)
            @modalsRegion.show(@configNote)
        else
            configDeferred.resolve()
        configDeferred.done ()=>
            if @userModel.isLoggedIn()
                @getVersionAndDeploy()
            else
                @showLogin()

    getVersionAndDeploy: ()=>
        versionsDeferred = @getVersionsDeferred()
        versionsDeferred.fail (response)=>
            @reportError(strings.VERSION_FETCH_FAILURE_TITLE(), response.responseText, @showVersionsFailureNote)
        versionsDeferred.done ()=>
            @showClusterConfig()


    getVersionsDeferred: ()=>
        deferred = @userModel.getVersionsDeferred()
        @createDeferredIndication(deferred)
        return deferred

    createDeferredIndication: (deferred)=>
        if @modalsRegion.currentView?
            @modalsRegion.currentView.setButtonDeferred(deferred)
        else
            @getChosenClusterOption()?.setButtonDeferred(deferred)

    login: ()=>
        if @userModel.isLoggedIn()
            return
        if window.IS_HP
            @loginWindow = window.open("https://accounts.google.com/o/saml2?idpid=C00vrdpke", '_blank', 'height=600,width=450')
        else
            @loginWindow = window.open("#{consts.releasesBucketURL}#{consts.releasesAPIPaths.login}?next=#{window.location.origin}\
            /static/.gen/html/loginSuccess.html", '_blank', 'height=600,width=450')

    logout: ()=>
        @clusterSettingsModel?.resetVersions()
        if @clusterConfiguration?.isRendered
            @dismissModal()

    recieveMsg: (event)=>
        if event.origin != window.location.origin
            return
        if not event.data.code?
            return
        code = event.data.code
        @userModel.login(code)

    showLoginFailure: (response)=>
        @reportError(strings.LOGIN_FAILURE_TITLE(), response.responseText)

    showDialog: (dialog)=>
        @listenTo(dialog, 'dismiss', @dismissModal)
        @modalsRegion.show(dialog)

    showErrorDialog: (dialog)=>
        currentDialog = @modalsRegion.currentView
        if currentDialog? and not currentDialog.isError
            currentDialog.showErrorDialog(dialog)
        else
            @showDialog(dialog)

    reportError: (title, msg, action)=>
        @errorPopup = new popupFactory.createError(title: title, text: msg, action: action)
        @showErrorDialog(@errorPopup)

    dismissTooltips: (event)=>
        @calculator.updateShownTT(undefined)

    adjustHeightForTT: (inner_popup)=>
        if not @calculator.shownTT?
            @calculator.$el.css("min-height", '0px')
            return
        if inner_popup?
            el = inner_popup.$el
        else
            el = @calculator.shownTT.$el
        diff = el.offset().top + el.outerHeight() + 30 - @footerContent.$el.offset().top
        if diff > 0 or @calculator.$el.css("min-height") != '0px'
            height = @calculator.$el.outerHeight()
            @calculator.$el.css("min-height", height + diff + "px")

    openRegionInput: ()=>
        @dismissModal()
        @calculator.openRegionInput()

    dismissModal: ()=>
        @modalsRegion.reset()

    goToDocs: =>
        docs = if window.IS_HP? then consts.docsLinks.root else consts.docsLinks.main
        window.open(docs, '_blank');

    showContactUsForm: ()=>
        contactUsContetnt = new ContactUsForm(reqsModel: @calculator.reqsModel)
        @contactUsForm = popupFactory.createCustom(
            class: 'contactUs',
            title: strings.CONTACT_US_TITLE(),
            subTitle: strings.CONTACT_US_SUB_TITLE(),
            body: contactUsContetnt
        )
        @listenTo(@contactUsForm, 'dismiss', @dismissModal)
        @modalsRegion.show(@contactUsForm)

    initialize: (options)=>
        if options.stage == 'prod'
            @calcURL = consts.calcURLs.prod
        else if @options.stage == 'dev'
            @calcURL = consts.calcURLs.dev
        else
            @calcURL = consts.calcURLs.local
        @searchParams = new URLSearchParams(window.location.search)
        if @searchParams.has('requirements')
            @reqsData = JSON.parse(@searchParams.get('requirements'))
        @shouldLogout = options.shouldLogout
        @userModel = new UserModel()
        window.addEventListener("message", @recieveMsg)


module.exports = CalculatorLayout