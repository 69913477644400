FileSaver = require('file-saver');
XLSX = require('xlsx');

BOMModalTmpl = require('./BOMModal.hbs')
consts = require('../../../framework/consts.ecmascript')
utils = require('../../../framework/utils.coffee');

class BOMModalView extends Marionette.LayoutView
  template: BOMModalTmpl
  ui:
    dismissButton: '.dismiss'
    saveButton: '.save'
    selects: 'select'
    bomName: 'input'
  events:
    'click @ui.dismissButton': 'dismiss'
    'click @ui.saveButton': 'saveBOM'

  initialize: (data)=>
    @data = data
    @formIsCorrect = true

  dismiss: ()=>
    @trigger('closeDialog')
  
  createBOMXlsxData: (output) =>
    skuData = []
    
    for key, value of output
      if key == 'weka_license_sku'
        sku = value['SSD'][@data.customer_type][@data.license]
        sku.quantity = if @data.weka_license_unit == "net" then @data.required_capacity else Math.round(parseFloat(@data.total_servers_raw))
        skuData.push([sku.priority || 0, parseInt(sku.quantity), sku.SKU, sku.description, sku.adhoc])
        if @data.cold_capacity > 0
          sku = value['OBS'][@data.customer_type][@data.license]
          sku.quantity = @data.cold_capacity
          skuData.push([sku.priority || 0, parseInt(sku.quantity), sku.SKU, sku.description, sku.adhoc])
      else
        for sku in value
          if @data.cold_capacity > 0 && "conditional-quantity" of sku
            quantity = sku["conditional-quantity"]["WarmTierExists"]
          else if "conditional-quantity" of sku
            quantity = sku["conditional-quantity"]["default"]
          else
            quantity = sku.quantity
          
          if key == 'cluster_skus'
            multiplier = 1
          if key == 'enclosure_skus'
            multiplier = @data.enclosures
          if key == 'server_skus'
            multiplier = @data.servers
          if key == 'ssd_skus'
            multiplier = @data.servers * @data.populated_ssds
          skuData.push([sku.priority || 0, quantity * multiplier, sku.SKU, sku.description])
    skuData.sort((a,b) => a[0] - b[0])
    
    # Adding excel headers
    xlsxData = []
    dataForLog = {}
    dataForLogCounter = 1
    splittedPreamble = @data.bom_dialog.formatting.preamble.split("\\n")
    xlsxData.push([splittedPreamble[0]])
    xlsxData.push(splittedPreamble[1].split(","))
    # Adding excel data
    for row in skuData
      row.shift() # remove priority value
      xlsxData.push(row)
      dataForLog["row " + dataForLogCounter] = row.join(",")
      dataForLogCounter += 1
    
    # Send log event
    if window.location.hostname != "localhost"
      utils.post(consts.APIs.logger, {
        event_type: 'BOM Creation',
        hostname: window.location.hostname,
        timezone_offset: new Date().getTimezoneOffset(),
        email: localStorage.getItem('email'),
        data: dataForLog
      })
    return xlsxData

  sendBOMEmail: (fileBlob, bomName) =>
    reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    reader.onloadend = () =>
      base64data = reader.result
      utils.post(consts.APIs.sendEmail, {
        subject: "BOM file was created by " + localStorage.getItem('user'),
        message: "BOM file is attached",
        email: @data.notification_email,
        attachment: base64data,
        filename: bomName
      })

  saveBOM: ()=>
    # Updating the output from the form
    output = JSON.parse(JSON.stringify(@data.bom_dialog.output))
    @formIsCorrect = true
    bomName = @ui.bomName.val()
    dialogSelectedOptions = {}
    for select in @ui.selects
      if !select.value || !bomName
        @formIsCorrect = false
      else
        dialogSelectedOptions[select.name] = select.value
        option = Array.from(@data.bom_dialog["config dialog"]).filter((o) => o.name == select.name)[0]
        for o in option.values[select.value]["skus"]
          output[option.element_unit].push(o)

    # Checking if form is valid
    if !@formIsCorrect
      alert("Please fill all the fields")
    else
      # Send log event
      if window.location.hostname != "localhost"
        utils.post(consts.APIs.logger, {
          event_type: 'BOM Dialog',
          hostname: window.location.hostname,
          email: localStorage.getItem('email'),
          timezone_offset: new Date().getTimezoneOffset(),
          data: dialogSelectedOptions
        })

      # Creating excel file
      @trigger('closeDialog')
      wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "BOM",
        Subject: "BOM",
        Author: "Weka",
        CreatedDate: new Date()
      };
        
      # Creating excel sheet and adding output data
      wb.SheetNames.push("BOM")
      ws_data = @createBOMXlsxData(output)
      ws = XLSX.utils.aoa_to_sheet(ws_data)

      # Setting auto width for excel columns
      objectMaxLength = []
      [startLetter, endLetter] = ws['!ref'].replace(/\d/, '').split(':')
      ranges = _.range(startLetter.charCodeAt(0), endLetter.charCodeAt(0) + 1)
      for c in ranges
        cell = String.fromCharCode(c)
        maxLength = 0
        for i in _.range(1,100)
          if ws['' + cell + i]
            cellLength = ws['' + cell + i].v.length + 1
            maxLength = if cellLength > maxLength then cellLength else maxLength
        objectMaxLength.push({ width: maxLength })
      ws['!cols'] = objectMaxLength

      # Download the excel
      wb.Sheets["BOM"] = ws;    
      wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'})
      s2ab = (s) =>
        buf = new ArrayBuffer(s.length)
        view = new Uint8Array(buf)
        for i in [0..s.length-1]
          view[i] = s.charCodeAt(i) & 0xFF
        return buf
      fileBlob = new Blob([s2ab(wbout)])
      FileSaver.saveAs(fileBlob, bomName + '.xlsx');
      @sendBOMEmail(fileBlob, bomName + '.xlsx')

  serializeData: =>
    return {
      data: @data.bom_dialog["config dialog"],
      warmTierExists: @data.cold_capacity > 0
    }

module.exports ={
  BOMModalView: BOMModalView
}