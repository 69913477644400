sizerStrings = require('../../framework/sizerStrings.ecmascript');
paramHelpTmpl = require('./tmpl/paramHelp.hbs')
helpTmpl = require('./tmpl/helpPopup.hbs')

class HelpPopup extends Marionette.ItemView
    template: helpTmpl
    className: "HelpPopup"
    events:
        'click': 'preventDefaultClick'

    dismiss: ()=>
        @trigger('dismiss')

    initialize: (options)=>
        @helpText = options.helpText
        @title = options.title
        @.$el.addClass(options.extraClass)

    isInner: =>
        return @.$el.hasClass('moreInfoPopup')

    preventDefaultClick: (e)=>
        e.stopPropagation()

    serializeData: =>
        return {
            title: @title
            helpText: @helpText
        }


class ParamHelpPopup extends Marionette.LayoutView
    template: paramHelpTmpl
    className: 'HelpPopup ' + (if window.IS_HP then '' else 'smallPopup') 
    regions:
        moreInfoRegion: '.moreInfoRegion'
    ui:
        moreInfoLabel: '.helpMoreInfo'
    events:
        'click @ui.moreInfoLabel': 'moreInfoClick'
        'click': 'dismissInfo'

    moreInfoClick: (e)=>
        e.stopPropagation()
        if @infoPopup?
            @dismissInfo(e)
        else
            @showInfo()

    dismissInfo: (e)=>
        e.stopPropagation()
        if @infoPopup?
            dismissing = @infoPopup
            @infoPopup = undefined
            @moreInfoRegion.reset()
            @trigger('change:helpShown', dismissing)

    showInfo: ()=>
        @infoPopup = new HelpPopup(helpText: @moreInfoText, extraClass: 'moreInfoPopup')
        @listenTo(@infoPopup, 'dismiss', @dismissInfo)
        @moreInfoRegion.show(@infoPopup)
        @trigger('change:helpShown', @infoPopup)

    dismiss: ()=>
        @trigger('dismiss')

    initialize: (options)=>
        @title = options.help.title
        @subTitle = options.help.subTitle
        @moreInfoLabel = options.help.moreInfoLabel
        @moreInfoText = options.help.moreInfoText
        @rows = options.help.rows
        @secondaryRows = options.help.secondaryRows
        @colKey = options.help.colKey
        @withCold = options.cluster.attributes.cold_capacity > 0
        for row in @rows
            if row.title == "Total hardware cost" && @withCold 
                row.title = "Total infrastructure cost"
            row.value = options.cluster.getFormattedValue(row.key, @colKey)
        if @secondaryRows
            for row in @secondaryRows
                row.value = options.cluster.getFormattedValue(row.key, @colKey)
                if @withCold && !Array.isArray(row.value)
                    row.value = ['', '$' + row.value, '']
                else if @withCold
                    row.value = _.map(row.value, (v) => '$' + v)
                else
                    row.value = '$' + row.value                   

    serializeData: =>
        return {
            title: @title,
            rows: @rows,
            subTitle: @subTitle,
            secondaryRows: @secondaryRows,
            moreInfo: @moreInfo,
            moreInfoLabel: @moreInfoLabel,
            showAsTable: window.IS_HP && @withCold && @secondaryRows,
            total: sizerStrings.COST_BREAKOUT_HEADER_TOTAL,
            hot: sizerStrings.COST_BREAKOUT_HEADER_HOT,
            cold: sizerStrings.COST_BREAKOUT_HEADER_COLD
        }


module.exports = {
	HelpPopup: HelpPopup,
	ParamHelpPopup: ParamHelpPopup
}