loginIndicationTmpl = require('./tmpl/loginIndication.hbs')
headerTmpl = require('./tmpl/header.hbs')
headerTopTmpl = require('./tmpl/headerTop.hbs')
introTmpl = require('./tmpl/intro.hbs')
sizerIntroTmpl = require('./tmpl/sizerIntro.hbs')
strings = require('../framework/strings.ecmascript')
common = require('../widgets/misc/common.coffee')
consts = require('../framework/consts.ecmascript')

class CalculatorHeaderView extends Marionette.LayoutView
    template: headerTmpl
    className: 'CalculatorHeaderView'
    regions:
        topRegion: '.topRegion'
        introRegion: '.introRegion'

    initialize: (options)=>
        @reqsModel = options.reqsModel
        @userModel = options.userModel

    onRender: =>
        @topContent = new CalculatorHeaderTopView(reqsModel: @reqsModel)
        @listenTo(@topContent, 'contact_us', @showContactUsForm)
        @listenTo(@topContent, 'go_to_docs', @goToDocs)
        @topRegion.show(@topContent)
        if window.IS_HP
            @introContent = new SizerHeaderIntro(userModel: @userModel)
        else
            @introContent = new HeaderIntro(userModel: @userModel)
        @listenTo(@introContent, 'contact_us', @showContactUsForm)
        @listenTo(@introContent, 'go_to_docs', @goToDocs)
        @introRegion.show(@introContent)

    showContactUsForm: ()=>
        @trigger('contact_us')

    goToDocs: ()=>
        @trigger('go_to_docs')


class CalculatorHeaderTopView extends Marionette.LayoutView
    template: headerTopTmpl
    className: 'HeaderTopView'
    regions:
        buttonsRegion: '.buttonsWrapper'

    initialize: (options)=>
        @reqsModel = options.reqsModel

    onRender: =>
        @buttonsView = new common.ContactButtonsView()
        @listenTo(@buttonsView, 'contact_us', @showContactUsForm)
        @listenTo(@buttonsView, 'go_to_docs', @goToDocs)
        @buttonsRegion.show(@buttonsView)

    showContactUsForm: ()=>
        @trigger('contact_us')

    goToDocs: ()=>
        @trigger('go_to_docs')


class HeaderIntro extends Marionette.LayoutView
    className: 'HeaderIntro'
    template: introTmpl
    ui:
        contactUs: '#contact_us_link'
        docsLink: '#docs_link'
    regions:
        loginRegion: '.loginIndicationRegion'
    events:
        'click @ui.contactUs': 'showContactUsForm'
        'click @ui.docsLink': 'goToDocs'

    showContactUsForm: (e)=>
        e.preventDefault()
        @trigger('contact_us')

    goToDocs: (e)=>
        e.preventDefault()
        @trigger('go_to_docs')

    initialize: (options)=>
        @userModel = options.userModel

    onRender: ()=>
        @loginIndication = new LoginIndication(model: @userModel)
        @loginRegion.show(@loginIndication)

    serializeData: ()=>
        return {
            docsURL: if window.IS_HP? then consts.docsLinks.root else consts.docsLinks.main
        }


class SizerHeaderIntro extends Marionette.LayoutView
    className: 'HeaderIntro'
    template: sizerIntroTmpl
    ui:
        contactUs: '#contact_us_link'
        docsLink: '#docs_link'
    regions:
        loginRegion: '.loginIndicationRegion'
    events:
        'click @ui.contactUs': 'showContactUsForm'
        'click @ui.docsLink': 'goToDocs'

    showContactUsForm: (e)=>
        e.preventDefault()
        @trigger('contact_us')

    goToDocs: (e)=>
        e.preventDefault()
        @trigger('go_to_docs')

    initialize: (options)=>
        @userModel = options.userModel

    onRender: ()=>
        @loginIndication = new LoginIndication(model: @userModel)
        @loginRegion.show(@loginIndication)

    serializeData: ()=>
        return {
            docsURL: if window.IS_HP? then consts.docsLinks.root else consts.docsLinks.main
        }


class LoginIndication extends Marionette.LayoutView
    className: 'LoginIndication'
    template: loginIndicationTmpl
    regions:
        loaderRegion: '.loader_region'
    ui:
        log: '.log'
        name: '.userName'
    events:
        'click @ui.log': 'log'

    showLoading: =>
        @ui.name.text(strings.LOGGING_IN())
        @ui.log.addClass('hidden')
        @loaderRegion.show(new common.Loader(type: 'header_login'))

    log: ()=>
        if @model.isLoggedIn() || localStorage.getItem('sizerPartnerToken')
            @model.logout()
        else
            @model.triggerShowLogin()

    onRender: ()=>
        if @model.isLoggedIn()
            @listenToOnce(@model, 'logout', @render)
        if @model.isLoggingIn()
            @setLoggingDeferred(@model.loggingDeferred)
        else
            @listenToOnce(@model, 'new_login', @setLoggingDeferred)

    setLoggingDeferred: (deferred)=>
        @showLoading()
        deferred.always ()=>
            @render()

    initialize: (options)=>
        @model = options.model

    serializeData: =>
        name = @model.getName()
        logText = if name? then strings.LOGOUT() else strings.LOGIN()
        if window.IS_HP
            logText = strings.LOGOUT()
        nameMsg = if name? then strings.LOGGED_IN_MSG(name: name) else strings.LOGGED_OUT_MSG()
        if window.IS_HP
            nameMsg = strings.LOGGED_IN_MSG(name: localStorage.getItem('user'))
        return {
            logText: logText,
            nameMsg: nameMsg
        }



module.exports = CalculatorHeaderView