strings = require('../framework/strings.ecmascript')
sizerStrings = require('../framework/sizerStrings.ecmascript')
regionErrorTmpl = require('./tmpl/regionError.hbs')
reqTmpl = require('./tmpl/requirement.hbs')
reqGroupTmpl = require('./tmpl/requirementsGroup.hbs')
helpPopups = require('../widgets/help_popups/help_popups.coffee')
Inputs = require('../widgets/inputs/inputs.coffee')
InputsFactory = Inputs.InputsFactory
vendorsTmpl = require('./tmpl/vendors.hbs')
consts = require('../framework/consts.ecmascript')


class RegionNote extends Marionette.ItemView
    template: regionErrorTmpl
    className: "noteBlock"
    ui:
        dismissButton: '.closeNote'
    events:
        'click @ui.dismissButton': 'dismiss'

    dismiss: ()=>
        @trigger('dismiss')

    serializeData: =>
        return {msg: strings.SINGLE_REGION_NOTE_MSG()}

class LayoutViewWithHelp extends Marionette.LayoutView
    ui:
        helpIcon: '.help_icon'
    events:
        'click @ui.helpIcon': 'helpClick'

    triggerHelpShown: (popup)=>
        @trigger('change:helpShown', popup)

    showHelp: (title, text)=>
        @helpPopup = new helpPopups.HelpPopup(title: title, helpText: text)
        @listenTo(@helpPopup, 'dismiss', @dismissHelp)
        @helpRegion.show(@helpPopup)
        @triggerHelpShown(@helpPopup)

    dismissHelp: ()=>
        if @helpPopup?
            @helpPopup = undefined
            @helpRegion.reset()
            @triggerHelpShown(undefined)
    
    helpClick: (e)=>
        e.stopPropagation()
        if @helpPopup?
            @dismissHelp()
        else
            @showHelp(@name, @getHelpText())

class Requirement extends LayoutViewWithHelp
    template: reqTmpl
    className: 'RequirementRow formatRow'
    regions:
        contentRegion: '.req_input'
        helpRegion: '.help_icon'

    initialize: (options)=>
        @reqsModel = options.reqsModel
        @name = options.name
        @helpText = options.attrs.helpText
        @label = options.attrs.label
        @inputAttrs = options.attrs.input
        if @inputAttrs['type'] in ['switch', 'radio', 'checkbox']
            @inputAttrs['name'] = @name
        if @name == 'totalCapacity'
            @inputAttrs['value'] = @reqsModel.capacity
            @inputAttrs['unit'] = if window.IS_HP then @reqsModel.capacityUnits.toUpperCase() else @reqsModel.capacityUnits
        else
            @inputAttrs['value'] = @reqsModel[@name]
        @input = InputsFactory.createInput(@inputAttrs)
        @listenTo(@input, 'change', @triggerChange)

    showSSDCapacity: (size, unit)=>
        @input.showSSDCapacity(size, unit)

    getHelpText: ()=>
        return @helpText

    getUnit: ()=>
        return @input.getUnit()

    getSize: ()=>
        return @input.getSize()

    getInputVal: ()=>
        return @input.getInputVal()

    getVal: ()=>
        return @input.getVal()

    setVal: (val)=>
        @input.setVal(val)

    disable: (bool)=>
        if bool
            @.$el.addClass('disabledRow')
        else
            @.$el.removeClass('disabledRow')
        @setDisabledInput()

    hide: (bool)=>
        if bool
            @.$el.addClass('hidden')
        else
            @.$el.removeClass('hidden')
        @setDisabledInput()

    setDisabledInput: ()=>
        if @.$el.hasClass('hidden') or @.$el.hasClass('disabledRow')
            @input.setDisabled?(true)
        else
            @input.setDisabled?(false)

    changeText: (text)=>
        @input.changeText(text)

    triggerChange: (value)=>
        if window.IS_HP
            if @name == 'totalCapacity'
                @reqsModel.capacity = @input.getInputVal()
            if @inputAttrs['type'] == 'switch' or @inputAttrs['type'] == 'dropdown'
                @reqsModel[@name] = @input.getVal()
            else if @inputAttrs['type'] == 'radio' or @inputAttrs['type'] == 'number'
                @reqsModel[@name] = value
        else
            if @name == 'totalCapacity'
                @reqsModel.capacity = @input.getSize()
                @reqsModel.capacityUnits = @input.getUnit()
            else
                @reqsModel[@name] = @input.getVal()
        @trigger('change')

    serializeData: =>
        return {
            title: @label
            showHelp: window.IS_HP && (@name == "deploymentType" || @name == "features")
        }

    onRender: ()=>
        if @input?
            @contentRegion.show(@input)

class RequirementsGroup extends LayoutViewWithHelp
    template: reqGroupTmpl
    className: if window.IS_HP then 'RequirementsGroup SizerRequirementsGroup' else 'RequirementsGroup'
    regions:
        helpRegion: '.help_icon'
        reqsSection: '.reqsSection'

    getGroupHelp: ()=>

    getHelpText: ()=>
        html = @getGroupHelp()
        if html?
            BRs = '<br><br>'
        else
            html = ''
            BRs = ''
        for name, req of @reqsMap
            if req.helpText?
                html = html.concat(BRs + req.helpText)
                BRs = '<br><br>'
                docsLink = consts.docsLinks[name]
                if docsLink?
                    html = html.concat(strings.DOCS_LINK(link: docsLink))
        return html

    listenToReqs: (reqs, trigger, callback)=>
        reqs ?= @requirements
        for req in reqs
            @listenTo(req, trigger, callback)

    triggerCalc: ()=>
        @trigger('calc')

    addReqRegions: ()=>
        regions = {}
        for req in @reqs
            regions["#{req}Region"] = ".region_#{req}"
        @addRegions(regions)

    onRender: ()=>
        @requirements = []
        for req, attrs of @reqsMap
            @[req] = new Requirement(name: req, attrs: attrs, reqsModel: @reqsModel)
            @requirements.push(@[req])
            @["#{req}Region"].show(@[req])
        @listenToReqs(undefined, 'change:helpShown', @triggerHelpShown)
        @listenToReqs(undefined, 'change', @triggerCalc)
        

    initialize: (options)=>
        @reqsModel = options.reqsModel
        @reqs = _.keys(@reqsMap)
        @addReqRegions()

    serializeData: (options) =>
        # Here I check in which requirement groups I need to show help
        # The decision is made by the name of the first field
        return Object.assign({
            name: @name
            reqs: _.keys(@reqsMap)
            needToShowHelp: if window.IS_HP && _.includes(["totalCapacity", "license", "sort"], _.keys(@reqsMap)[0]) then false else true
            isSizerCapacity: window.IS_HP && _.keys(@reqsMap)[0] == "totalCapacity"
            firstCapacityTabActive: _.includes(['true', null], localStorage.getItem('firstCapacityTabActive'))
        }, options)


class RegionGroup extends RequirementsGroup
    reqsMap:
        'region':
            helpText: strings.REGION_HELP_TEXT()
            label: strings.REQ_REGION_LABEL()
            input:
                type: 'dropdown'
                class: 'regions openUp'
                options: consts.regionOptions

    openRegionInput: ()=>
        @region.input.setExpanded(true)

    initialize: (options)=>
        super(options)
        @name = strings.REQ_REGION_LABEL()

    serializeData: =>
        options = {noTitle: true}
        super(options)


class PerformanceGroup extends RequirementsGroup
    reqsMap:
        'performance':
            input:
                type: 'checkbox'
                label: strings.REQ_PERFORMANCE_LABEL()
        'performanceUnits':
            input:
                type: 'radio'
                options:[
                    {
                        name: strings.IOPS()
                        value: 'iops'
                    },
                    {
                        name: strings.BW()
                        value: 'bw'
                    }
                ]
        'rw':
            helpText: strings.RW_HELP_TEXT()
            label: strings.REQ_RW_LABEL()
            input:
                type: 'number'
                float: true
                min: 0
                max: 100
                unit: '%'
        'iops':
            helpText: strings.IOPS_HELP_TEXT()
            label: strings.IOPS()
            input:
                type: 'number'
                min: 0
                unit: if window.IS_HP then 'M' else 'K'
        'bw':
            helpText: strings.BW_HELP_TEXT()
            label: strings.BW()
            input:
                type: 'number'
                min: 0
                placeholder: strings.BW_INPUT_PLACEHOLDER()
                unit: strings.BW_INPUT_UNITS()


    getGroupHelp: ()=>
        return if window.IS_HP then sizerStrings.PERFORMANCE_GROUP_HELP_TEXT() else strings.PERFORMANCE_GROUP_HELP_TEXT()

    getHelpText: ()=>
        html = @getGroupHelp()
        BRs = '<br><br>'
        if window.IS_HP && localStorage.getItem('firstCapacityTabActive') == 'false'
            html = html.concat(BRs + @rw.helpText)
        else
            for name, req of @reqsMap
                if req.helpText?
                    html = html.concat(BRs + req.helpText)
        return html

    onRender: ()=>
        super()
        if _.includes(['true', null], localStorage.getItem('firstCapacityTabActive'))
            @performanceUnits.$el.parent().css('display', 'block')
            @iops.$el.parent().css('display', 'block')
            @bw.$el.parent().css('display', 'block')
        else
            @performanceUnits.$el.parent().css('display', 'none')
            @iops.$el.parent().css('display', 'none')
            @bw.$el.parent().css('display', 'none')

        @listenTo(@performance, 'change', @togglePerformance)
        @listenTo(@performanceUnits, 'change', @unitsChange)
        if !window.IS_HP
            @togglePerformance()
        @unitsChange()

    togglePerformance: ()=>
        if @performance.getVal()
            for req in @requirements
                if req != @performance
                    req.disable(false)
        else
            for req in @requirements
                if req != @performance
                    req.disable(true)

    unitsChange: ()=>
        if @performanceUnits.getVal() == 'iops'
            @iops.hide(false)
            @bw.hide(true)
        else
            @iops.hide(true)
            @bw.hide(false)

    initialize: (options)=>
        super(options)
        @name = strings.REQ_PERFORMANCE_LABEL()

    serializeData: =>
        options = {noTitle: true}
        super(options)


class CapacityGroup extends RequirementsGroup
    reqsMap:
        'totalCapacity':
            helpText: strings.TOTAL_CAPACITY_HELP_TEXT()
            label: strings.REQ_TOTAL_CAPACITY_LABEL()
            input:
                type: 'capacity'
                float: true
                min: 1
        'tiering':
            helpText: strings.TIERING_HELP_TEXT()
            label: strings.REQ_TIERING_LABEL()
            input:
                type: 'switch'
                leftLabel: strings.NO_TIERING_LABEL()
                rightLabel: strings.S3_TIERING_LABEL()
        'SSDPercentage':
            label: strings.REQ_SSD_PERCENTAGE_LABEL()
            input:
                type: 'number'
                float: true
                min: 1
                max: 100
                unit: '%'
        'SSDCapacity':
            helpText: strings.SSD_CAPACITY_HELP_TEXT()
            label: strings.REQ_SSD_CAPACITY_LABEL()
            input:
                type: 'number'
                unit: 'TB'
                disabled: true
        

    getGroupHelp: ()=>
        return strings.CAPACITY_GROUP_HELP_TEXT()

    getOBSLabel: ()=>
        return strings.S3_TIERING_LABEL()

    onRender: ()=>
        super()
        @listenTo(@totalCapacity, 'change', @calculateSSDCapacity)
        @listenTo(@tiering, 'change', @toggleSSDFields)
        @listenTo(@SSDPercentage, 'change', @calculateSSDCapacity)
        @toggleSSDFields()

    toggleSSDFields: ()=>
        if @tiering.getVal()
            @SSDPercentage.hide(false)
            @SSDCapacity.hide(false)
            @calculateSSDCapacity()
        else
            @SSDPercentage.hide(true)
            @SSDCapacity.hide(true)

    calculateSSDCapacity: ()=>
        totalSize = @totalCapacity.getSize()
        unit = @totalCapacity.getUnit().toUpperCase()
        percents = @SSDPercentage.getVal()
        SSDSize = totalSize * percents / 100
        @SSDCapacity.showSSDCapacity(SSDSize, unit)

    initialize: (options)=>
        super(options)
        @name = strings.REQ_GROUP_CAPACITY_LABEL()
        @reqsMap.tiering.input.rightLabel = @getOBSLabel()


class FiltersGroup extends RequirementsGroup
    reqsMap:
        'sort':
            label: sizerStrings.OPTIMIZATION_LABEL()
            input:
                type: 'radio'
                options:[
                    {
                        name: sizerStrings.SORT_BY_COST_LABEL()
                        value: "by_cost"
                    },
                    {
                        name: sizerStrings.SORT_BY_SPACE_LABEL()
                        value: 'by_space'
                    }
                ]


class VendorsGroup extends RequirementsGroup
    template: vendorsTmpl
    ui:
        vendorCheckbox: '.checkbox'
    events:
        'click @ui.vendorCheckbox': 'toggleVendor'

    initialize: (data)=>
        @name = sizerStrings.REQ_GROUP_VENDORS_LABEL()
        @vendors = data.vendors
        if !localStorage.getItem('selectedVendors')
            localStorage.setItem('selectedVendors', JSON.stringify(Array.from(data.vendors)))
    
    serializeData: =>
        if @vendors
            selectedVendors = new Set(JSON.parse(localStorage.getItem('selectedVendors')))
            vndrs = Array.from(@vendors).map((v) => [v, selectedVendors.has(v)])
        else
            vndrs = []
        return {
            vendors: vndrs,
            name: @name
        }

    toggleVendor: (event) =>
        vendor = event.target.id
        selectedVendors = new Set(JSON.parse(localStorage.getItem('selectedVendors')))
        if selectedVendors.has(vendor) then selectedVendors.delete(vendor) else selectedVendors.add(vendor)
        localStorage.setItem('selectedVendors', JSON.stringify(Array.from(selectedVendors)))
        @trigger('change', vendor)
        

module.exports = {
    RequirementsGroup: RequirementsGroup,
    VendorsGroup: VendorsGroup,
    FiltersGroup: FiltersGroup,
    CapacityGroup: CapacityGroup,
    PerformanceGroup: PerformanceGroup,
    RegionGroup: RegionGroup,
    RegionNote: RegionNote
}
