oneOptionTmpl = require('./tmpl/dropdown_option.hbs')
dropDownTmpl = require('./tmpl/dropdown.hbs')

class DropDownOption extends Backbone.Model
    getText: =>
        return @get('text')

    getValue: =>
        return @get('value')

    getDivider: =>
        return @get('divider')

    getBeta: =>
        return @get('beta')

    initialize: (options)=>
        @selected = options.selected ? false

class DropDownOptionView extends Marionette.LayoutView
    className: 'OneOptionItemView'
    template: oneOptionTmpl
    tagName: 'span'

    triggerSelect: (ev) =>
        ev.stopPropagation()
        if @isDivider()
            return
        @trigger('select')

    getValue: =>
        return @model.getValue()

    getText: =>
        return @model.getText()

    getBeta: =>
        return @model.getBeta()

    isDivider: =>
        return @model.getDivider()

    isSelected: ()=>
        return @model.selected

    select: (bool)=>
        @model.selected = bool
        if bool
            @.$el.addClass('selectedOption')
        else
            @.$el.removeClass('selectedOption')

    onRender: ()=>
        if @isDivider()
            @.$el.addClass('divider')
            @.$el.removeClass('OneOptionItemView')
        else
            @.$el.click(@triggerSelect)
            if @getBeta()
                @.$el.addClass('betaOption')

    serializeData: =>
        return {
            text: @getText()
            beta: @getBeta()
        }


class DropDownOptions extends Backbone.Collection
    model: DropDownOption

class DropDownOptionsView extends Marionette.CollectionView
    className: 'OptionsCollectionView'
    childView: DropDownOptionView
    childEvents:
        'select': 'selectOption'

    getFirstOption: =>
        return @children.find((option) -> return not option.isDivider())

    getOptionByValue: (value) =>
        return @children.find((option) -> return option.getValue() == value)

    getSelected: ()=>
        return @children.find((option) -> return option.isSelected())

    selectOption: (optionView) =>
        @trigger('selectOption', optionView)


class DropDownMenu extends Marionette.LayoutView
    className: 'DropDownMenuWidget'
    template: dropDownTmpl

    ui:
        box: '.box'
        value: '.value'
        selected: '.selectedPart'
        optionsContainer: '.optionsList'

    regions:
        optionsRegion: '.optionsRegion'

    events:
        'click @ui.selected': 'toggleState'
        'focusout @ui.box': 'collapse'


    collapse: ()=>
        if @expanded
            @setExpanded(false)

    toggleState: (ev)=>
        @setExpanded(not @expanded)

    setExpanded: (bool)=>
        @expanded = bool
        if bool
            @ui.box.addClass('opened')
            @ui.box.focus()
            if @.$el.hasClass('versions')
                @setWidth()
        else
            if (typeof @ui.box != 'string')
                @ui.box.removeClass('opened')

    getVal: ()=>
        return @selectedView.getValue()

    setVal: (value) =>
        if value?
            optionView = @optionsView.getOptionByValue(value)
        else
            optionView = @optionsView.getFirstOption()
        if !optionView
            optionView = @optionsView.getFirstOption()
        @selectOption(optionView)

    selectOption: (optionView) =>
        if @selectedView?
            previous = @selectedView
            @selectedView.select(false)
        @selectedView = optionView
        @selectedView.select(true)
        @ui.value.text(@selectedView.getText())
        if previous? and previous != @selectedView
            @trigger('change')
        @setExpanded(false)

    initialize: (options) =>
        @optionsList = new DropDownOptions(options.options)
        @class = options.class
        @value = options.value
        @maxWidth = options.maxWidth

    setWidth: ()=>
        optionsWidth = @ui.optionsContainer.width()
        if @ui.box.width() < optionsWidth
            if @maxWidth?
                @ui.box.width(Math.min(optionsWidth, @maxWidth))
            else
                @ui.box.width(optionsWidth)


    onRender: =>
        @optionsView = new DropDownOptionsView(collection: @optionsList)
        @optionsRegion.show(@optionsView)
        @setVal(@value)
        @setExpanded(false)
        @listenTo(@optionsView, 'selectOption', @selectOption)
        @.$el.addClass(@class)

module.exports = DropDownMenu
