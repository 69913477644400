const consts = {
	regionOptions: [
		{
			text: 'US East (N. Virginia)',
			value: 'us-east-1'
		},
		{
			text: 'US East (Ohio)',
			value: 'us-east-2'
		},
		{
			text: 'US West (N. California)',
			value: 'us-west-1'
		},
		{
			text: 'US West (Oregon)',
			value: 'us-west-2'
		},
		{
			text: 'EU (Frankfurt)',
			value: 'eu-central-1'
		},
		{
			text: 'EU (Ireland)',
			value: 'eu-west-1'
		},
		{
			text: 'EU (London)',
			value: 'eu-west-2'
		},
		{
			text: 'EU (Paris)',
			value: 'eu-west-3'
		},
		{
			text: 'EU (Stockholm)',
			value: 'eu-north-1'
		},
		{
			text: 'Asia Pacific (Singapore)',
			value: 'ap-southeast-1'
		},
		{
			text: 'Asia Pacific (Sydney)',
			value: 'ap-southeast-2'
		},
		{
			text: 'Asia Pacific (Tokyo)',
			value: 'ap-northeast-1'
		},
		{
			text: 'Asia Pacific (Seoul)',
			value: 'ap-northeast-2'
		},
		{
			text: 'Asia Pacific (Osaka-Local)',
			value: 'ap-northeast-3'
		},
		{
			text: 'Asia Pacific (Mumbai)',
			value: 'ap-south-1'
		},
		{
			text: 'Asia Pacific (Hong Kong)',
			value: 'ap-east-1'
		},
		{
			text: 'South America (Sao Paulo)',
			value: 'sa-east-1'
		},
		{
			text: 'Middle East (Bahrain)',
			value: 'me-south-1'
		},
		{
			text: 'Canada (Central)',
			value: 'ca-central-1'
		},
	],
	singleAZ: ['ap-northeast-3', 'ca-central-1'],
	backendTypes: [
		'i3.xlarge',
		'i3.2xlarge',
		'i3.4xlarge',
		'i3.8xlarge',
		'i3.16xlarge',
		'i3en.xlarge',
		'i3en.2xlarge',
		'i3en.3xlarge',
		'i3en.6xlarge',
		'i3en.12xlarge',
		'i3en.24xlarge',
	],
	clientTypes: [
		'c5.2xlarge',
		'c5.4xlarge',
		'c5.9xlarge',
		'c5.12xlarge',
		'c5.18xlarge',
		'c5.24xlarge',
		'c5n.2xlarge',
		'c5n.4xlarge',
		'c5n.9xlarge',
		'c5n.18xlarge',
		'c6a.2xlarge',
		'c6a.4xlarge',
		'c6a.8xlarge',
		'c6a.12xlarge',
		'c6a.16xlarge',
		'c6a.32xlarge',
		'c6a.48xlarge',
		'c6in.2xlarge',
		'c6in.4xlarge',
		'c6in.8xlarge',
		'c6in.12xlarge',
		'c6in.16xlarge',
		'c6in.24xlarge',
		'c6in.32xlarge',
		'c7i.2xlarge',
		'c7i.4xlarge',
		'c7i.8xlarge',
		'c7i.12xlarge',
		'c7i.16xlarge',
		'c7i.24xlarge',
		'c7i.48xlarge',
		'g3.4xlarge',
		'g3.8xlarge',
		'g3.16xlarge',
		'g4dn.2xlarge',
		'g4dn.4xlarge',
		'g4dn.8xlarge',
		'g4dn.12xlarge',
		'g4dn.16xlarge',
		'i3.xlarge',
		'i3.2xlarge',
		'i3.4xlarge',
		'i3.8xlarge',
		'i3.16xlarge',
		'i3en.xlarge',
		'i3en.2xlarge',
		'i3en.3xlarge',
		'i3en.6xlarge',
		'i3en.12xlarge',
		'i3en.24xlarge',
		'inf2.xlarge',
		'inf2.8xlarge',
		'inf2.24xlarge',
		'inf2.48xlarge',
		'm5.xlarge',
		'm5.2xlarge',
		'm5.4xlarge',
		'm5.8xlarge',
		'm5.12xlarge',
		'm5.16xlarge',
		'm5.24xlarge',
		'm5n.xlarge',
		'm5n.2xlarge',
		'm5n.4xlarge',
		'm5n.8xlarge',
		'm5n.12xlarge',
		'm5n.16xlarge',
		'm5n.24xlarge',
		'm6i.xlarge',
		'm6i.2xlarge',
		'm6i.4xlarge',
		'm6i.8xlarge',
		'm6i.12xlarge',
		'm6i.16xlarge',
		'm6i.24xlarge',
		'm6i.32xlarge',
		'm6idn.xlarge',
        'm6idn.2xlarge',
        'm6idn.4xlarge',
        'm6idn.8xlarge',
        'm6idn.12xlarge',
        'm6idn.16xlarge',
        'm6idn.24xlarge',
        'm6idn.32xlarge',
        'm7a.xlarge',
        'm7a.2xlarge',
        'm7a.4xlarge',
        'm7a.8xlarge',
        'm7a.12xlarge',
        'm7a.16xlarge',
        'm7a.24xlarge',
        'm7a.32xlarge',
        'm7a.48xlarge',
		'p2.xlarge',
		'p2.8xlarge',
		'p2.16xlarge',
		'p3.2xlarge',
		'p3.8xlarge',
		'p3.16xlarge',
		'r5.xlarge',
		'r5.2xlarge',
		'r5.4xlarge',
		'r5.8xlarge',
		'r5.12xlarge',
		'r5.16xlarge',
		'r5.24xlarge',
		'r5n.xlarge',
		'r5n.2xlarge',
		'r5n.4xlarge',
		'r5n.8xlarge',
		'r5n.12xlarge',
		'r5n.16xlarge',
		'r5n.24xlarge',
		'x1.16xlarge',
		'x1.32xlarge',
		'x1e.16xlarge',
		'x1e.32xlarge',
	],
	releasesAPIPaths: {
		auth: '/api/v1/auth/self',
		login: '/oauth',
		getToken: '/api/v1/oauth/claim',
		cfn: '/dist/v1/aws/cfn/',
		trunks: '/dist/v1/trunk',
		versions: '/dist/v1/release'
	},
	releasesBucketURL: 'https://get.weka.io',
	calcURLs: {
		local: '/api/calc',
		dev: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/WekaAWScalculator',
		prod: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/WekaAWScalculator'
	},
	APIs: {
		contactUs: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/SendContactUsForm',
		sendEmail: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/SendEmail',
		allowedSites: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/AllowedSites',
		token: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/Token',
		logger: 'https://ximlov1oh3.execute-api.eu-west-1.amazonaws.com/prod/Logger'
	},
	defaultReqs: {
		capacity: window.IS_HP ? 600 : 15,
		coldCapacity: 0,
		capacityUnits: 'tb',
		tiering: false,
		SSDPercentage: 20,
		performance: false,
		performanceUnits: 'iops',
		features: 'GA',
		capacityType: false,
		deploymentType: 'production',
		customerType: 'commercial',
		license: '3Y',
		sort: 'by_cost',
		rw: window.IS_HP ? 100 : 80,
		iops: window.IS_HP ? 1 : 100,
		bw: 1,
		region: 'us-east-1',
		vendor: localStorage.getItem('ra') ? JSON.parse(localStorage.getItem('ra')).vendor : '',
		ra: localStorage.getItem('ra') ? JSON.parse(localStorage.getItem('ra')).instance_name : '',
		numberOfEnclosures: '',
		SSDsPerEnclosure: window.IS_HP && localStorage.getItem('ra') ? JSON.parse(localStorage.getItem('ra')).ssds_options[0] : '',
	},
	clusterDefaults: {
		version: undefined,
		determinedVersion: true,
		withClients: true,
		clientsType: 'c7i.2xlarge',
		clientsCount: 2,
		customBE: false,
		BEType: 'i3en.2xlarge',
		BECount: 6,
		ami: 'default',
		customAmi: undefined
	},
	defaultAmi: 'Amazon Linux 2017.09',
	docsLinks: {
		root:'https://docs.weka.io/',
		main:'https://docs.weka.io/install/aws/self-service-portal',
		tiering: 'https://docs.weka.io/overview/data-storage'
	},
	colorNoteTimeout: 500
};
module.exports = consts;
