var strings = {
    RESULTS_TITLE: _.template("Your Options"),
    HP_CLUSTERS_VENDOR_HEADER: _.template("Vendor<br><br><br>"),
    HP_CLUSTERS_TABLE_INSTANCE_HEADER: _.template("Reference<br>Architecture<br>Name"),
    HP_U_SPACES: _.template("Rack Space<br>(Us)<br><br>"),
    HP_POPULATED_SSDS: _.template("# of NVMes<br>per server<br><br>"),
    CLUSTERS_TABLE_CAPACITY_HEADER: _.template("Net Capacity<br>(TB)<br><br>"),
    CLUSTERS_TABLE_COST_HEADER: _.template("Budgetary<br>Street<br>Price ($)<br>"),
    CLUSTERS_TABLE_IOPS_HEADER: _.template("IOPS<br>(M)<br><br>"),
    CLUSTERS_TABLE_BW_HEADER: _.template("BW<br>(Gbytes/sec)<br><br>"),
    COST_INFO_TITLE: _.template("Cost breakdown"),
    WEKA_TOTAL_HARDWARE_COST_LABEL: _.template("Total hardware cost"),
    WEKA_TRANSFER_COST_LABEL: _.template("Weka transfer price"),
    WEKA_RESELLER_LICENSE_MARKUP_LABEL: _.template("Reseller license markup"),
    COST_INFO_SUBTITLE: _.template("Cost effectiveness"),
    COST_PER_TB_LABEL: _.template("Capacity ($/TB)"),
    COST_PER_IOPS_LABEL: _.template("Performance ($/IOPS)"),
    COST_PER_BW_LABEL: _.template("Bandwidth ($/Gbytes/s)"),
    MORE_INFO_LABEL: _.template("More info"),
    REQ_NET_CAPACITY_LABEL: _.template("capacity Hot(NVME)"),
    REQ_COLD_CAPACITY_LABEL: _.template("Warm(OBS)"),
    REQ_GROUP_VENDORS_LABEL: _.template("OUTPUT CONTROL"),
    REQ_CAPACITY_TYPE_HOT_LABEL: _.template("Hot Only"),
    REQ_CAPACITY_TYPE_HOT_AND_COLD_LABEL: _.template("Hot + Warm"),
    REQ_VENDOR_LABEL: _.template("Vendor"),
    REQ_RA_LABEL: _.template("RA"),
    REQ_NUMBER_OF_ENCLOSURES_LABEL: _.template("# of Enclosures"),
    REQ_SSDS_PER_ENCLOSURE_LABEL: _.template("NVMe per Server"),
    OPTIMIZATION_LABEL: _.template("Optimization"),
    SORT_BY_COST_LABEL: _.template("Cost"),
    SORT_BY_SPACE_LABEL: _.template("Space"),
    FUTURE_VERSIONS_LABEL: _.template("Feature set"),
    DEPLOYMENT_TYPE_HELP_LABEL: _.template("At this moement there is no difference between POC and production, this can be updated in the future. "),
    DEPLOYMENT_TYPE_LABEL: _.template("Deployment type"),
    PRODUCTION_LABEL: _.template("Production"),
    POC_LABEL: _.template("POC"),
    ON_LABEL: _.template("Future"),
    OFF_LABEL: _.template("GA"),
    LICENSE_PERIOS_LABEL: _.template("License"),
    PERIOD_1Y_LABEL: _.template("1Y"),
    PERIOD_3Y_LABEL: _.template("3Y"),
    PERIOD_5Y_LABEL: _.template("5Y"),
    CUSTOMER_TYPE_LABEL: _.template("Customer type"),
    COMMERCIAL_LABEL: _.template("Enterprise"),
    GOV_EDU_LABEL: _.template("Gov/Edu"),
    REQ_GROUP_CAPACITY_LABEL: _.template("system capacity"),
    REQ_CUSTOMER_AND_DEAL_GROUP_LABEL: _.template("customer and deal"),
    CREATE_BOM_BUTTON: _.template("BOM"),
    COST_BREAKOUT_HEADER_TOTAL: _.template("Total"),
    COST_BREAKOUT_HEADER_HOT: _.template("Hot (NVME)"),
    COST_BREAKOUT_HEADER_COLD: _.template("Warm (Object)"),

    EXPORT_HEADER_SSDS_PER_SERVER: _.template("#of SSDs<br>per server<br>"),
    EXPORT_HEADER_TOTAL_SSD_RAW_CAPACITY: _.template("Total SSD<br>raw capacity"),
    EXPORT_HEADER_NUMBER_OF_SERVERS: _.template("# of<br>servers"),

    OPTION_INFO_TITLE: _.template("Details"),
    OPTION_DETAILS_ENCLOSURES_LABEL: _.template("Number of enclosures"),
    OPTION_DETAILS_U_SPACES_LABEL: _.template("Number of Us"),
    OPTION_DETAILS_SERVERS_LABEL: _.template("Number of servers"),
    OPTION_DETAILS_SSDS_LABEL: _.template("NVMes per servers"),
    OPTION_DETAILS_STRIPING_LABEL: _.template("Striping size"),
    OPTION_DETAILS_HOT_SPARES_LABEL: _.template("# of hot spares"),
    OPTION_DETAILS_RAW_CAPACITY_LABEL: _.template("Total NVMe raw capacity"),
    OPTION_DETAILS_NETWORK_PORTS_LABEL: _.template("Total # of network ports"),

    RA_DETAILS_SSD_BAYS_LABEL: _.template("# of NVMe bays"),
    RA_DETAILS_SSD_CAPACITY_LABEL: _.template("NVMe capacity"),
    RA_DETAILS_SSD_MODEL_LABEL: _.template("NVMe model"),
    RA_DETAILS_CPUS_NUMBER_LABEL: _.template("# CPUs"),
    RA_DETAILS_CORES_PER_CPU_LABEL: _.template("Cores per CPU"),
    RA_DETAILS_CPU_MODEL_LABEL: _.template("CPU model"),
    RA_DETAILS_CPU_CLOCK_LABEL: _.template("CPU clock"),
    RA_DETAILS_NETWORK_PORTS_LABEL: _.template("# network ports"),
    RA_DETAILS_NETWORK_PORT_SPEED_LABEL: _.template("Network port speed"),
    RA_DETAILS_US_LABEL: _.template("Us"),
    RA_DETAILS_SERVERS_PER_ENCLOSURES_LABEL: _.template("Servers per enclosures"),

    PERFORMANCE_GROUP_HELP_TEXT: _.template(
        "This section is optional and lets you specify your application performance requirements. Performance can be specified in either \
                    IOPS (I/O operations per seconds) or bandwidth (GBytes per second). Performance calculations rely on benchmarks of Weka systems."
    ),
    FUTURE_VERSIONS_HELP_TEXT: _.template("<b>Feature Set:</b> Activating this option will show Weka planing based on short-term product improvement, as follows: \
            <ul> \
            <li>Multi-container would improve IOPS performance</li> \
            </ul> \
        Please note the following: \
            <ul> \
             <li>Consult with VP Product (George Pamboris) for a roadmap of software features</li> \
            </ul>"
    )
};

const partnerSpecificLabels = JSON.parse(localStorage.getItem('label_modifier'))
if (partnerSpecificLabels) {
    for (const [key, value] of Object.entries(partnerSpecificLabels)) {
        strings[key] = _.template(value)
    }
}

module.exports = strings;
