popupTmpl = require('./tmpl/popup_msg.hbs')
subPopupTmpl = require('./tmpl/sub_popup_msg.hbs')
bodyTmpl = require('./tmpl/popup_body.hbs')
strings = require('../../framework/strings.ecmascript')
common = require('../misc/common.coffee')

class Popup extends Marionette.LayoutView
    className: 'Popup'
    template: popupTmpl
    regions:
        bodyRegion: '.bodyRegion'
        alertRegion: '.subPopupRegion'
    ui:
        underlayer: '.popupUnderlayer'
        close: '.popupClose'
    events:
        'click @ui.underlayer': 'dismissIfNoDeferred'
        'click @ui.close': 'dismiss'

    isError: false

    dismiss: ()=>
        @trigger('dismiss')

    dismissIfNoDeferred: ()=>
        if not @hasDeferred()
            @trigger('dismiss')

    hasDeferred: =>
        return @body.hasDeferred?()

    dismissSubPopup: ()=>
        @alertRegion.reset()

    change_region: ()=>
        @trigger('change_region')

    setButtonDeferred: (deferred) =>
        @body.setButtonDeferred(deferred)

    showErrorDialog: (dialog)=>
        @listenTo(dialog, 'dismiss', @dismissSubPopup)
        @alertRegion.show(dialog)

    showError: (title, msg, action)=>
        @errorPopup = new ErrorPopup(title: title, text: msg, action: action)
        @showErrorDialog(@errorPopup)

    onRender: ()=>
        @listenTo(@body, 'showError', @showError)
        @listenTo(@body, 'dismiss', @dismiss)
        @listenTo(@body, 'change_region', @change_region)
        @bodyRegion.show(@body)
        @.$el.addClass(@class)

    initialize: (options)=>
        @title = options.title
        @body = options.body
        @class = options.class
        @subPopup = options.sub
        @subTitle = options.subTitle


    serializeData: =>
        return {
            title: @title
            subTitle: @subTitle
        }

class PopupBody extends Marionette.LayoutView
    className: 'PopupBody'
    template: bodyTmpl
    regions:
        contentRegion: '.contentRegion'
        buttonRegion: '.buttonRegion'

    hasDeferred: =>
        return @button.hasDeferred()

    setButtonDeferred: (deferred) =>
        @button.setDeferred(deferred)

    triggerDismiss: ()=>
        @trigger('dismiss')

    onRender: ()=>
        @contentRegion.show(@content)
        @buttonRegion.show(@button)
        @listenTo(@button, 'click', @action)

    initialize: (options)=>
        @action = options.button.action
        @action ?= @triggerDismiss
        @button = new common.ElipsButton(options.button)
        @content = options.content
        @class = options.class

    serializeData: =>
        return {
            button: @button
        }

class TextContent extends Marionette.ItemView
    className: 'TextContent'
    template: _.template('<%= text %>')
    tagName: 'p'

    initialize: (options)=>
        @text = options.text

    serializeData: =>
        return {text: @text}


class ErrorPopup extends Popup
    template: subPopupTmpl
    isError: true
    initialize: (options)=>
        options.button = {
            text: strings.OK()
            type: 'no_action'
            action: options.action
        }
        options.content = new TextContent(options)
        options.body = new PopupBody(options)
        options.class = 'ErrorPopup'
        super(options)


class popupFactory
    @createError: (options)=>
        return new ErrorPopup(options)
    @createNote: (options)=>
        options.content = new TextContent(options)
        options.body = new PopupBody(options)
        return new Popup(options)
    @createCustom: (options)=>
        return new Popup(options)

module.exports = popupFactory
